import moment from "moment";
import styles from '../../styles/Search.module.css'
import styleTabs from '../../styles/UserTabs.module.css'
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchPosts } from "store/modules/posts/postsSlice";
import PostsFilter from "./Partials/postsFilter";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { selectPostForDetails } from "store/modules/posts/selectedPostSlice";
import { selectAvatarForDetails } from "store/modules/users/avatars/selectedAvatarSlice";
import Details from "./Partials/Details";

export default function Posts({ postId, avatarId, targetType, title, dateFrom, dateTo }) {
    const verifyPost = useUserPermission(SMIPermissionsCode.MOD_MANAGE);
    const navigate = useNavigate();

    const avatarIconObject = { key: 'avatarIcon' }

    const [postsHeaders, setPostsHeaders] = useState([
        { title: 'Postado', key: 'date', appear: true },
        { title: 'Título', key: 'title', copy: 'postId', detailable: 'true', appear: true },
        { title: 'Tipo', key: 'targetType', appear: true },
        { title: 'Avatar', key: 'avatarFullName', selectable: true, copy: 'avatarId', icon: () => avatarIconObject, appear: true },

    ]);

    const [postsMomentHeaders, setPostsMomentHeaders] = useState([...postsHeaders]);

    const handleAppearHeader = (item) => {
        setPostsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setPostsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const posts = useSelector((state) => {
        return Array.isArray(state.posts.data) ? state.posts.data : []
    })

    const selectedPost = useSelector((state) => {
        return state.selectedPost
    })

    const skip = useSelector((state) => {
        return (state.posts.currentSkip) ? state.posts.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.posts.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.posts.filters) ? state.posts.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [posts]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (postId || avatarId || targetType || title || dateFrom || dateTo) {
            setLoading(true)
            fetchPosts(dispatch, { postId, avatarId, targetType, title, dateFrom, dateTo }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.postId) query.append('postId', filter.form.postId);
        if (filter.form.avatarId) query.append('avatarId', filter.form.avatarId);
        if (filter.form.targetType) query.append('targetType', filter.form.targetType);
        if (filter.form.title) query.append('title', filter.form.title);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        window.history.pushState({}, '', `/posts?${query.toString()}`);
        fetchPosts(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchPosts(dispatch, filter.form, skip + 200);
    })

    const handleSelectRedirect = (item, cell) => {
        if (cell.key == 'avatarFullName') {
            dispatch(selectAvatarForDetails({ avatarId: item.avatarId }))
            navigate(`/users/avatars?avatarId=${item.avatarId}`)
        }
    }

    const handlePostsDetails = (item) => {
        dispatch(selectPostForDetails({ postId: item.postId }))
    }

    return (
            <div className='grid grid-cols-12 gap-4'>
    
                {verifyPost && <div className='col-span-6'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={postsHeaders}
                        headers={postsMomentHeaders}
                        slotHeader={<PostsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                        title='Buscar Postagens'
                        onDetailRow={handlePostsDetails}
                        isLoading={isLoading && posts.length === 0}
                        slotActions={
                            <>
                                <Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />
                                {/*<Button text="Criar Postagem" onClick={handleOpenCreateModal} color="cyan" />*/}
                            </>
                        }
                        items={posts}
                        onSelectRow={handleSelectRedirect}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(posts.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : posts.length} resultados</span>}
                            </div>
                        }
                    />
                </div>}
                
                <div className='col-span-6'>
    
                    {selectedPost.postId && <Details item={selectedPost} />}
    
                </div>
            </div>
        )


}