import { uuidMask } from 'helpers/masks'
import DefaultForm from 'components/form/DefaultForm'
import { useDispatch } from 'react-redux';
import { fetchPrivateChatLogsFilters } from 'store/modules/moderation/privateChatLogs/privateChatLogsSlice';

export default function ChatLogsFilter ({onFilterChange, defaultValues}) {

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;

        fetchPrivateChatLogsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'avatarId',
            label: 'ID do Avatar',
            value: defaultValues.avatarId,
            placeholder: 'ID do Avatar',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'chatId',
            label: 'ID do Chat',
            value: defaultValues.chatId,
            placeholder: 'ID do Chat',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'dateFrom',
            label: 'De:',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'datetime-local',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até:',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'datetime-local',
            gridClass: 'col-span-4'
        },
        {
            name: 'messageText',
            label: 'Texto na Mensagem',
            value: defaultValues.messageText,
            placeholder: 'Texto na Mensagem',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        }, 
    ]
    

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}
