import Tabs from 'components/Tabs'
import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable';
import Modal from 'components/Modal';
import style from '../../../../styles/UserTabs.module.css'
import styles from '../../../../styles/Search.module.css'

import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/form/Button';
import { toast } from 'react-toastify';

import { Paths } from 'paths/Paths';

import useUserPermission from 'domains/Permissions/useUserPermission';
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode';
import { usePrivateChat } from './privateChatSearch';
import { fetchSelectedPrivateChatDetails } from 'store/modules/moderation/privateChats/selectedPrivateChatDetailSlice';
import { deletePrivateChatMember, editPrivateChatMember, savePrivateChat, savePrivateChatMember } from 'services/privateChatService';
import { fetchPrivateChatMembers } from 'store/modules/moderation/privateChats/privateChatMembersSlice';
import { fetchPrivateChats } from 'store/modules/moderation/privateChats/privateChatsSlice';
import { cleanPrivateChatForDetails } from 'store/modules/moderation/privateChats/selectedPrivateChatSlice';
import { useNavigate } from 'react-router-dom';

export default function Details({ item }) {
    const verifyEditSpace = useUserPermission(SMIPermissionsCode.SPACE_DETAILS_EDIT);
    const verifySpaceMembers = useUserPermission(SMIPermissionsCode.SPACE_MEMBERS_VIEW);
    const verifySpaceMembersEdit = useUserPermission(SMIPermissionsCode.SPACE_MEMBERS_EDIT);
    //ABA PROPRIEDADES

    const privateChatHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const navigate = useNavigate();

    const firstPrivateChatData = usePrivateChat(item);
    const [privateChatData, setPrivateChatData] = useState(firstPrivateChatData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [isDeletePrivateChat, setIsDeletePrivateChat] = useState(false);

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstPrivateChatDataCopy = { ...firstPrivateChatData }
        setPrivateChatData(firstPrivateChatDataCopy)

        setOriginalValues({
            chatId: firstPrivateChatData.chatId,
            chatName: firstPrivateChatData.chatName,
            color: firstPrivateChatData.color,
        })

        setChangedValues({
            chatId: firstPrivateChatData.chatId,
            chatName: firstPrivateChatData.chatName,
            color: firstPrivateChatData.color,
        })

    }, [firstPrivateChatData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID do Chat") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
        else {
            navigate(`/moderation/private-chat-logs?chatId=${item.value}`)
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let privateChatDataCopy = { ...privateChatData, tableProperties: [...privateChatData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            if (modalTitle === 'Cor' && inputValue.length !== 7) {
                return;
            }

            for (let i = 0; i < privateChatData.tableProperties.length; i++) {
                if (privateChatData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    privateChatDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    privateChatDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                    console.log(changedValuesCopy)
                }
            }
            setChangedValues(changedValuesCopy)
            setPrivateChatData(privateChatDataCopy);
        }

        setCurrentItem({});
        setInputValue("");
        handleCloseModal();
        console.log(changedValues)

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setPrivateChatData(firstPrivateChatData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                chatName: 'name',
                color: 'color',
            }

            let changes = {}


            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            await savePrivateChat(item.id, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            await fetchSelectedPrivateChatDetails(dispatch, { id: item.id });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    //ABA MEMBROS

    const avatarIconObject = { key: 'avatarIcon' }

    const privateChatMembersHeaders = [
        { title: 'Papel', key: 'avatarRole' },
        { title: 'Avatar', key: 'avatarName', copy: 'avatarId', icon: () => avatarIconObject, detailable: true },
    ]

    const [privateChatMembersIsLoading, setPrivateChangeMembersLoading] = useState(false);

    const privateChatMembers = useSelector((state) => {
        return Array.isArray(state.privateChatMembers.data) ? state.privateChatMembers.data : []
    })

    const privateChatMembersSkip = useSelector((state) => {
        return (state.privateChatMembers.currentSkip) ? state.privateChatMembers.currentSkip : 0
    })
    const privateChatMembersHasMore = useSelector((state) => {
        return Boolean(state.privateChatMembers.hasMore)
    })

    const [roleInputValue, setRoleInputValue] = useState(1);
    const [idInputValue, setIdInputValue] = useState('');
    const [isModalAddMemberOpen, setIsModalAddMemberOpen] = useState(false)

    const [isModalEditMemberOpen, setIsModalEditMemberOpen] = useState(false)
    const [avatarRole, setAvatarRole] = useState(0);
    const [avatarId, setAvatarId] = useState("");
    const [originalAvatarRole, setOriginalAvatarRole] = useState(0);

    useEffect(() => {
        setPrivateChangeMembersLoading(false)
    }, [privateChatMembers]);

    useEffect(() => {
        setPrivateChangeMembersLoading(true)
        fetchPrivateChatMembers(dispatch, { chatId: item.id }, 0);
    }, [item])

    const privateChatMembersHandleLoadMore = (() => {
        setPrivateChangeMembersLoading(true);
        fetchPrivateChatMembers(dispatch, { chatId: item.id }, skip + 200);
    })

    const handleOpenAddMemberModal = () => {
        setIsModalAddMemberOpen(true)
    }

    const handleCloseAddMemberModal = () => {
        setIdInputValue("");
        setRoleInputValue(1);
        setOriginalAvatarRole(0);
        setIsModalAddMemberOpen(false)
    }

    const handleOnChangeRoleInput = (event) => {
        setRoleInputValue(event.target.value)
    }

    const handleOnChangeIdInput = (event) => {
        setIdInputValue(event.target.value)
    }

    const saveMember = async () => {
        let check = false;
        for (let i = 0; i < privateChatMembers.length; i++) {
            if (privateChatMembers[i].avatarId == idInputValue) {
                check = true
            }
        }
        if (idInputValue != "") {
            const role = {
                1: 'admin',
                2: 'member'
            }
            try {
                if (!check) {
                    await savePrivateChatMember(item.id, { 'avatar_id': idInputValue, 'role': role[roleInputValue] });
                    setPrivateChangeMembersLoading(true);
                    await fetchPrivateChatMembers(dispatch, { chatId: item.id }, 0);
                    toast.success('Dados salvos com Sucesso!');
                }
                else {
                    toast.error('Avatar já é membro!');
                }
            }
            catch {
                toast.error('Erro ao salvar!');
            }
            handleCloseAddMemberModal();
        }
    }

    const handleOpenEditMemberModal = () => {
        setIsModalEditMemberOpen(true)
    }

    const handleCloseEditMemberModal = () => {
        setAvatarRole(0);
        setAvatarId("");
        setIsModalEditMemberOpen(false)
    }

    const handleDetailableMember = (member) => {
        const avatarRoleCode = {
            'admin': 1,
            'member': 2,
        }

        setAvatarId(member.avatarId)
        setAvatarRole(avatarRoleCode[member.avatarRole]);
        setOriginalAvatarRole(avatarRoleCode[member.avatarRole]);
        handleOpenEditMemberModal();
    }

    const handleOnChangeEditRoleInput = (event) => {
        setAvatarRole(event.target.value)
    }

    const deleteMember = async () => {
        try {
            await deletePrivateChatMember(item.id, { 'avatar_id': avatarId });
            setPrivateChangeMembersLoading(true);
            await fetchPrivateChatMembers(dispatch, { chatId: item.id }, 0);
            toast.success('Membro deletado com Sucesso!');
        }
        catch {
            toast.error('Erro ao Deletar!');
        }
        handleCloseEditMemberModal();

    }

    const editMember = async () => {
        if (avatarRole != originalAvatarRole) {
            const role = {
                1: 'admin',
                2: 'member'
            }
            try {
                await editPrivateChatMember(item.id, { 'avatar_id': avatarId, 'role': role[avatarRole] });
                setPrivateChangeMembersLoading(true);
                await fetchPrivateChatMembers(dispatch, { chatId: item.id }, 0);
                toast.success('Membro Atualizado com Sucesso!');
            }
            catch {
                toast.error('Erro ao Atualizar Membro!');
            }
            handleCloseEditMemberModal();
        }
    }

    const handleDeletePrivateChat = async () => {
        try {
            await deletePrivateChat(item.id)

            await fetchPrivateChats(dispatch, {}, 0);
            dispatch(cleanPrivateChatForDetails())


            handleCloseDeletePrivateChatModal()
            toast.success('PrivateChat Removido com Sucesso!')
        }
        catch {
            toast.error('Erro ao Remover PrivateChat!')
        }
    }

    const handleOpenDeletePrivateChatModal = () => {
        setIsDeletePrivateChat(true)
    }

    const handleCloseDeletePrivateChatModal = () => {
        setIsDeletePrivateChat(false)
    }

    const tabs = []

    tabs.push({
        id: 1,
        tabTitle: 'Propriedades',
        content: (
            <>
                <BasicTable
                    round={false}
                    height='58.5vh'
                    onSelectRow={handleSelectRow}
                    headers={privateChatHeaders}
                    items={privateChatData.tableProperties}
                    slotHeader={(handleValueChange) ? "" :
                        <div className={style.header}>
                            <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                            <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                        </div>
                    }
                />
                {verifyEditSpace && <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                >
                    {
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>
                            {
                                (modalTitle != 'Cor') ?
                                    <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                    : <input className={style.modalInput} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                            }

                            <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                        </div>


                    }
                </Modal>}
            </>
        )
    })

    if (verifySpaceMembers) {
        tabs.push({
            id: 2,
            tabTitle: 'Membros',
            content: (
                <>
                    <BasicTable
                        title={'Membros'}
                        round={false}
                        height='46.5vh'
                        headers={privateChatMembersHeaders}
                        onDetailRow={handleDetailableMember}
                        items={privateChatMembers}
                        isLoading={privateChatMembersIsLoading && privateChatMembers.length === 0}
                        slotActions={verifySpaceMembersEdit && <Button text="Adicionar Membro" color="cyan" onClick={handleOpenAddMemberModal} disabled={false} />}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!privateChatMembersHasMore || privateChatMembersIsLoading} text="Carregar Mais" onClick={privateChatMembersHandleLoadMore} color="cyan" />
                                {(privateChatMembers.length === 0) ? "" : <span>Exibindo {0}-{(privateChatMembersHasMore) ? privateChatMembersSkip + 200 : privateChatMembers.length} resultados</span>}
                            </div>
                        }
                    />
                    {verifySpaceMembersEdit && <Modal
                        isOpen={isModalAddMemberOpen}
                        onClose={handleCloseAddMemberModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Papel`}</span>
                            <select value={roleInputValue} className={style.select} onChange={handleOnChangeRoleInput}>
                                <option key={'1'} value={1}>Admin</option>
                                <option key={'2'} value={2}>Apenas Membro</option>
                            </select>
                            <span className={style.membersModalLabel}>{`ID do Avatar`}</span>
                            <input className={style.modalInput} type="text" placeholder={'ID do Avatar'} value={idInputValue} onChange={handleOnChangeIdInput} />

                            <button className={style.modalButton} onClick={saveMember}>Salvar</button>
                        </div>
                    </Modal>}
                    {verifySpaceMembersEdit && <Modal
                        isOpen={isModalEditMemberOpen}
                        onClose={handleCloseEditMemberModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Papel`}</span>
                            <select value={avatarRole} className={style.select} onChange={handleOnChangeEditRoleInput}>
                                <option key={'1'} value={1}>Admin</option>
                                <option key={'2'} value={2}>Apenas Membro</option>
                            </select>

                            <div className={style.memberModalButtons}>
                                <button className={style.modalButton} onClick={editMember}>Alterar</button>
                                <button className={style.modalButton} onClick={deleteMember}>Excluir Membro</button>
                            </div>
                        </div>
                    </Modal>}
                </>
            )
        })
    }

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{item.chatName}</b>
                            <p>{item.id}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: "10px" }}>
                            {<Button text="Excluir" color="cyan" onClick={handleOpenDeletePrivateChatModal} />}
                        </div>
                    </div>
                )}
            />
            <Modal
                header={`Atenção!`}
                isOpen={isDeletePrivateChat}
                onClose={handleCloseDeletePrivateChatModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja apagar permanentemente o chat?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeletePrivateChat} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeletePrivateChatModal} color="cyan" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
