import { createSlice } from '@reduxjs/toolkit'
import { getPrivateChat } from 'services/privateChatService';

const selectedPrivateChatSlice = createSlice({
    name: 'selectedPrivateChat',
    initialState: {},
    reducers: {
        setSelectedPrivateChat: (state, action) => {
            let space;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                space = action.payload[0]
            } else {
                space = {}
            }            

            return space
        },
    },
})

export const selectPrivateChatForDetails = (params) => async (dispatch) => {
    try {
        const selectedPrivateChat = await getPrivateChat(params)
        dispatch(setSelectedPrivateChat(selectedPrivateChat))
    } catch (error) {
        console.error('Erro ao carregar Chat Privado:', error)
    }
}


export const cleanPrivateChatForDetails = () => async (dispatch) => {
    dispatch(setSelectedPrivateChat([]))
}

export const { setSelectedPrivateChat } = selectedPrivateChatSlice.actions
export default selectedPrivateChatSlice.reducer