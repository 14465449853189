import { getPosts } from "services/postsService";

const { createSlice } = require("@reduxjs/toolkit");

const postsSlice = createSlice({
    name: 'posts',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            postsId: '',
            avatarId: '',
            targetType: '',
            title: '',
            dateFrom: '',
            dateTo: '',
        }
    },
    reducers: {
        setPostsData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addPostsData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setPostsFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchPosts(dispatch, params, skip) {
    const LIMIT = 200;
    try {
        const posts = await getPosts(params, skip);
        dispatch(setCurrentSkip(skip));

        if (skip > 0) {
            dispatch(addPostsData(posts))
        }
        else {
            dispatch(setPostsData(posts));
        }

        if (posts.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch (error) {
        console.error('Erro ao obter Postagens', error)
    }
}

export async function fetchPostsFilters(dispatch, params) {
    dispatch(setPostsFilter(params));
}

export const { setPostsData, addPostsData, setCurrentSkip, setHasMore, setPostsFilter } = postsSlice.actions
export default postsSlice.reducer