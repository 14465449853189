import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedPrivateChatDetails } from "store/modules/moderation/privateChats/selectedPrivateChatDetailSlice";

export function usePrivateChat(item){
    const space = useSelector((state) => {
        return (state.selectedPrivateChatDetails.details) ? state.selectedPrivateChatDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedPrivateChatDetails(dispatch, { id: item.id });
    }, [item])

    return space
}