import moment from "moment"

class PostDetail {
    constructor({ postId, avatarId, targetType, title, date, avatarFirstName, avatarLastName, avatarNameInstance, avatarIcon, update, targetId, targetAvatarFirstName, targetAvatarLastName, targetAvatarNameInstance, targetPostTitle, }) {
        this.postId = postId
        this.avatarId = avatarId
        this.targetType = targetType
        this.title = title
        this.date = date
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.avatarIcon = avatarIcon
        this.update = update
        this.targetId = targetId
        this.targetAvatarFirstName = targetAvatarFirstName
        this.targetAvatarLastName = targetAvatarLastName
        this.targetAvatarNameInstance = targetAvatarNameInstance
        this.targetPostTitle = targetPostTitle
    }

    get() {
        let tableProperties
        if (this.targetType == 'A') {
            tableProperties = [
                {
                    key: 'postId',
                    name: "ID Post",
                    value: this.postId,
                },
                {
                    key: 'title',
                    name: "Título do Post",
                    value: this.title == '' ? '-' : this.title ?? '-',
                },
                {
                    key: 'avatarId',
                    name: "Id do Autor",
                    value: this.avatarId,
                },
                {
                    key: 'avatarFullName',
                    name: "Nome do Autor",
                    value: this.avatarFullName(this.avatarFirstName, this.avatarLastName, this.avatarNameInstance),
                },
                {
                    key: 'targetType',
                    name: "Tipo de Postagem",
                    value: this.getTargetType(this.targetType),
                },

                {
                    key: 'targetId',
                    name: "ID Perfil no qual foi postado",
                    value: this.targetId,
                },

                {
                    key: 'targetAvatarName',
                    name: "Nome Perfil no qual foi postado",
                    value: this.avatarFullName(this.targetAvatarFirstName, this.targetAvatarLastName, this.targetAvatarNameInstance),
                },


                {
                    key: 'date',
                    name: "Data de Criação",
                    value: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
                },
                {
                    key: 'update',
                    name: "Última Edição",
                    value: this.update ? moment(this.update).format('DD/MM/YYYY') + " " + moment(this.update).format('HH:mm:ss') : 'Não editado',
                },
            ]
        }
        else {
            tableProperties = [
                {
                    key: 'postId',
                    name: "ID Post",
                    value: this.postId,
                },
                {
                    key: 'title',
                    name: "Título do Post",
                    value: this.title == '' ? '-' : this.title ?? '-',
                },
                {
                    key: 'avatarId',
                    name: "Id do Autor",
                    value: this.avatarId,
                },
                {
                    key: 'avatarFullName',
                    name: "Nome do Autor",
                    value: this.avatarFullName(this.avatarFirstName, this.avatarLastName, this.avatarNameInstance),
                },
                {
                    key: 'targetType',
                    name: "Tipo de Postagem",
                    value: this.getTargetType(this.targetType),
                },

                {
                    key: 'targetId',
                    name: "ID Post Respondido",
                    value: this.targetId,
                },

                {
                    key: 'targetPostTitle',
                    name: "Título Post Respondido",
                    value: this.targetPostTitle == '' ? '-' : this.targetPostTitle ?? '-',
                },

                {
                    key: 'date',
                    name: "Data de Criação",
                    value: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
                },
                {
                    key: 'update',
                    name: "Última Edição",
                    value: this.update ? moment(this.update).format('DD/MM/YYYY') + " " + moment(this.update).format('HH:mm:ss') : 'Não editado',
                },
            ]
        }
        return {
            tableProperties: tableProperties,
        }

    }

    getTargetType(type) {
        const types = {
            A: 'Postada no Perfil',
            P: 'Resposta à uma postagem',
        }
        return types[type]
    }

    avatarFullName(avatarFirstName, avatarLastName, avatarNameInstance) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else return avatarFirstName + " " + avatarLastName;
    }
}

export default PostDetail