import React, { useState, useEffect } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../styles/Search.module.css'
import styleNews from '../../styles/News.module.css'
import styleTabs from '../../styles/UserTabs.module.css'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { fetchPackages } from 'store/modules/packages/packagesSlices'
import PackagesFilter from './PackagesFilter'
import { selectPackageForDetails } from 'store/modules/packages/selectedPackageSlice'
import Details from './Partials/Details'
import Tabs from 'components/Tabs'
import Modal from 'components/Modal'
import { fetchModelList } from 'store/modules/items/modelListSlice'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { createPackage, deletePackage } from 'services/packagesService'
import moment from 'moment'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'

export default function Packages({ packageId, packageName, active }) {
    const verifyPackages = useUserPermission(SMIPermissionsCode.PACKAGES_VIEW);
    const verifyPackagesManage = useUserPermission(SMIPermissionsCode.PACKAGES_MANAGE);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isFull, setIsFull] = useState(false);

    const [packagesHeaders, setPackagesHeaders] = useState([
        { title: 'ID do Pacote', key: 'packageId', copy: 'packageId', detailable: 'true', appear: true },
        { title: 'Nome', key: 'packageName', appear: true },
        { title: 'Ativo', key: 'active', appear: true },
    ]);

    const [packagesMomentHeaders, setPackagesMomentHeaders] = useState([...packagesHeaders]);

    const handleAppearHeader = (item) => {
        setPackagesHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setPackagesMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const packages = useSelector((state) => {
        return Array.isArray(state.packages.data) ? state.packages.data : []
    })


    const selectedPackage = useSelector((state) => {
        return state.selectedPackage
    })

    const skip = useSelector((state) => {
        return (state.packages.currentSkip) ? state.packages.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.packages.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.packages.filters) ? state.packages.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [packages]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (packageId || packageName || active) {
            setLoading(true)
            fetchPackages(dispatch, { packageId, packageName, active }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.packageId) query.append('packageId', filter.form.packageId);
        if (filter.form.packageName) query.append('packageName', filter.form.packageName);
        if (filter.form.active) query.append('active', filter.form.active);
        window.history.pushState({}, '', `/packages?${query.toString()}`);
        fetchPackages(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchPackages(dispatch, filter.form, skip + 200);
    })

    const handlePackageDetails = (item) => {
        console.log('aqui')
        console.log(item)
        dispatch(selectPackageForDetails({ packageId: item.packageId }))
    }

    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true)
    }

    const handleCloseCreateModal = () => {
        //tab1
        setNameInput('')
        setIdInput('')
        setFullWidth(false)
        setDisabled(false)
        setHasVipDiscount(false)
        setCurrency('brl')
        sePricesValueInput({ 'BRL': 0, 'USD': 0, 'EUR': 0, 'GBP': 0 })
        setImgValueInput({ 'BRL': '', 'USD': '', 'EUR': '', 'GBP': '' })
        setSortIndexInput(0)
        setStartDiscountInput('')
        setEndDiscountInput('')
        setDiscountInput(0)
        setLimitInput('')
        setStartDateInput('')
        setEndDateInput('')
        setDescInput('')

        //tab2
        setModelItems([])
        setSelectInput('')
        setGoldInput('')
        setTokensInput('')
        setConfigInput('')
        setQntInput(1)
        setModelIsValid(false)
        setGoldMetadataInput('')
        setClMetadataInput('')
        setVipMetadataInput('')

        setIsCreateModalOpen(false)
    }

    //tab 1
    const [nameInput, setNameInput] = useState('')
    const [idInput, setIdInput] = useState('')
    const [fullWidth, setFullWidth] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [hasVipDiscount, setHasVipDiscount] = useState(false)
    const [currency, setCurrency] = useState('brl')
    const [pricesValueInput, sePricesValueInput] = useState({ 'BRL': 0, 'USD': 0, 'EUR': 0, 'GBP': 0 })
    const [imgValueInput, setImgValueInput] = useState({ 'BRL': '', 'USD': '', 'EUR': '', 'GBP': '' })
    const [imgNameValueInput, setImgNameValueInput] = useState({ 'BRL': '', 'USD': '', 'EUR': '', 'GBP': '' })
    const [sortIndexInput, setSortIndexInput] = useState(0)
    const [startDiscountInput, setStartDiscountInput] = useState('')
    const [endDiscountInput, setEndDiscountInput] = useState('')
    const [discountInput, setDiscountInput] = useState(0)
    const [limitInput, setLimitInput] = useState('')
    const [startDateInput, setStartDateInput] = useState('')
    const [endDateInput, setEndDateInput] = useState('')
    const [descInput, setDescInput] = useState('')

    const handleImg = (e, lan) => {
        let tempImg = { ...imgValueInput, [lan]: e.target.files[0] }
        setImgValueInput(tempImg)
        let tempImgName = { ...imgNameValueInput, [lan]: e.target.files[0].name.trim().replace(/\s+/g, ""), }
        setImgNameValueInput(tempImgName)
    }

    const handlePrices = (e, lan) => {
        let tempTitle = { ...pricesValueInput, [lan]: e.target.value }
        sePricesValueInput(tempTitle)
    }

    //tab 2

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    const modelList = useSelector((state) => {
        return Array.isArray(state.modelList.data) ? state.modelList.data : []
    })

    useEffect(() => {
        if (modelList.length == 0) {
            fetchModelList(dispatch)
        }
    }, [])

    const iconObject = { key: 'modelIcon' }
    const modelItemsHeaders = [
        { title: 'ID do Modelo', key: 'modelId' },
        { title: 'Nome', key: 'modelName', icon: () => iconObject },
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' /> },
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' /> },
        { title: 'Configuração', key: 'config' },
        { title: 'Quantidade', key: 'qnt' }
    ]

    const [modelItems, setModelItems] = useState([])
    const [selectInput, setSelectInput] = useState('')
    const [goldInput, setGoldInput] = useState('')
    const [tokensInput, setTokensInput] = useState('')
    const [configInput, setConfigInput] = useState('')
    const [qntInput, setQntInput] = useState(1)
    const [modelIsValid, setModelIsValid] = useState(false)
    const [goldMetadataInput, setGoldMetadataInput] = useState('')
    const [clMetadataInput, setClMetadataInput] = useState('')
    const [vipMetadataInput, setVipMetadataInput] = useState('')

    const handleEditSelectInput = (option) => {
        setSelectInput(option)
    }

    useEffect(() => {
        if (selectInput != '' && goldInput != '' && tokensInput != '') {
            setModelIsValid(true)
        }
        else {
            setModelIsValid(false)
        }
    }, [selectInput, goldInput, tokensInput, configInput, qntInput])

    const handleDeleteModel = async (tableItem) => {
        try {
            let modelArr = [...modelItems];


            modelItems.map((item, i) => {
                if (item.modelId == tableItem.modelId) {
                    modelArr.splice(modelArr.indexOf(item), 1);
                }
            })
            console.log(modelArr)
            setModelItems(modelArr)
            toast.success('Item Removido com Sucesso')
        }
        catch {
            toast.error('Erro ao Remover Item')
        }

    }

    const handleAddItem = () => {
        let modelId = selectInput.value;
        let contains = false
        let tempModelItems = [...modelItems]
        for (const item of modelItems) {
            if (modelId == item.modelId) {
                contains = true
            }
        }
        if (!contains) {
            tempModelItems.push({
                modelId: modelId,
                modelName: selectInput.label,
                modelIcon: selectInput.icon,
                gold: goldInput,
                tokens: tokensInput,
                config: configInput,
                qnt: qntInput
            })

            setModelItems([...tempModelItems])

            toast.success('Item Adicionado com Sucesso!')
        }
        else {
            toast.error('Item já Adicionado!')
        }

        setSelectInput('')
        setGoldInput('')
        setTokensInput('')
        setConfigInput('')
        setQntInput(1)
    }


    //para ambas
    const areAllFieldsFilled = (inputObject) => {
        return Object.values(inputObject).every(value => value !== '');
    };

    useEffect(() => {
        if (nameInput.length != 0 && idInput.length != 0 && sortIndexInput.length != 0 && areAllFieldsFilled(pricesValueInput) && startDateInput.length != 0 && endDateInput.length != 0 && descInput.length != 0) {
            setIsFull(true)
            console.log('aqui')
        }
        else {
            setIsFull(false)
        }


    }, [
        nameInput,
        idInput,
        fullWidth,
        disabled,
        hasVipDiscount,
        currency,
        pricesValueInput,
        imgValueInput,
        imgNameValueInput,
        sortIndexInput,
        startDiscountInput,
        endDiscountInput,
        discountInput,
        limitInput,
        startDateInput,
        endDateInput,
        selectInput,
        goldInput,
        tokensInput,
        configInput,
        qntInput,
        modelIsValid,
        goldMetadataInput,
        clMetadataInput,
        vipMetadataInput,
        descInput,
    ])

    const handleCreatePackage = async () => {
        try {
            let items = []
            for (const modelItem of modelItems) {
                for(let i=0; i<modelItem.qnt;i++){
                    items.push({
                        model_id: modelItem.modelId,
                        priceGold: modelItem.gold,
                        priceTokens: modelItem.tokens,
                        config: modelItem.config
                    })
                } 
            }

            let metadata = {}

            if (goldMetadataInput.trim() != '' && goldMetadataInput != 0) {
                metadata['gold'] = String(goldMetadataInput)
            }

            if (vipMetadataInput.trim() != '' && vipMetadataInput != 0) {
                metadata['vip'] = String(vipMetadataInput)
            }

            if (clMetadataInput.trim() != '' && clMetadataInput != 0) {
                metadata['cl'] = String(clMetadataInput)
            }

            if (items.length != 0) {
                metadata['items'] = [...items]
            }

            await createPackage(
                {
                    _id: idInput,
                    name: nameInput,
                    fullWidth: fullWidth,
                    sortIndex: sortIndexInput,
                    metadata: metadata,
                    lifetimeLimit: limitInput.length == 0 ? null : Number(limitInput),
                    disabled: disabled,
                    currency: currency,
                    prices: { 'BRL': pricesValueInput['BRL'] * 100, 'USD': pricesValueInput['USD'] * 100, 'EUR': pricesValueInput['EUR'] * 100, 'GBP': pricesValueInput['GBP'] * 100 },
                    hasVipDiscount: hasVipDiscount,
                    discountEndDate: moment(endDiscountInput).utc().isValid() ? moment(endDiscountInput).utc().format() : null,
                    discountStartDate: moment(startDiscountInput).utc().isValid() ? moment(startDiscountInput).utc().format() : null,
                    priceDiscount: discountInput ?? null,
                    imgNames: imgNameValueInput,
                    value: Number(pricesValueInput[currency.toLocaleUpperCase()]) * 100,
                    startDate: moment(startDateInput).utc().format(),
                    endDate: moment(endDateInput).utc().format(),
                    desc: descInput
                },
                imgValueInput
            )


            handleCloseCreateModal();
            await fetchPackages(dispatch, {}, 0);
            toast.success("Dados Salvos com Sucesso!")
        }
        catch (ex) {
            console.log(ex)
            toast.error("Erro ao Salvar Dados!")
        }
    }

    const [packageIds, setPackageIds] = useState([]);
    const [isDelete, setIsDelete] = useState(false)

    const handleOpenDeleteModal = () => {
        setIsDelete(true)
    }

    const handleCloseDeleteModal = () => {
        setIsDelete(false)
    }

    const handleSelect = (item) => {
        let tempArr = [...packageIds];
        if (!tempArr.includes(item.packageId)) {
            tempArr.push(item.packageId);
        }
        else {
            let index = tempArr.indexOf(item.packageId);
            tempArr.splice(index, 1);

        }
        setPackageIds(tempArr);
    }

    const handleSelectAll = () => {
        let tempArr = []
        packages.map((item) => {
            tempArr.push(item.packageId)
        })
        if (JSON.stringify(tempArr) === JSON.stringify(packageIds)) {
            tempArr = []
        }
        setPackageIds(tempArr)
    }

    const handleDeleteAll = async () => {
        try {
            await deletePackage(packageIds)
            fetchPackages(dispatch, filter.form, 0);

            setPackageIds([])
            handleCloseDeleteModal()

            toast.success('Pacote(s) Deletado(s) com Sucesso!')
        }
        catch (ex) {
            console.log(ex)
            toast.error('Erro ao Deletar Pacote(s)!')
        }
    }

    const tabs = []


    tabs.push(
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', marginTop: '2px' }}>
                    <div className={styleNews.propContainerContent}>
                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`ID`}</span>
                            <input className={styleNews.modalInput} type="text" placeholder={`ID`} value={idInput} onChange={(e) => setIdInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Nome`}</span>
                            <input className={styleNews.modalInput} type="text" placeholder={`Nome`} value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Prioridade`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Prioridade`} value={sortIndexInput} min="0" onChange={(e) => setSortIndexInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Ocupa Linha Inteira`}</span>
                            <select value={fullWidth} className={styleTabs.select} onChange={(e) => setFullWidth(e.target.value)}>
                                <option key={'Sim'} value={true}>Sim</option>
                                <option key={'Não'} value={false}>Não</option>
                            </select>
                        </div>
                    </div>

                    <div className={styleNews.propContainerContent}>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Desabilitado`}</span>
                            <select value={disabled} className={styleTabs.select} onChange={(e) => setDisabled(e.target.value)}>
                                <option key={'Sim'} value={true}>Sim</option>
                                <option key={'Não'} value={false}>Não</option>
                            </select>
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Desconto Vip`}</span>
                            <select value={hasVipDiscount} className={styleTabs.select} onChange={(e) => setHasVipDiscount(e.target.value)}>
                                <option key={'Sim'} value={true}>Sim</option>
                                <option key={'Não'} value={false}>Não</option>
                            </select>
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Moeda`}</span>
                            <select value={currency} className={styleTabs.select} onChange={(e) => setCurrency(e.target.value)}>
                                <option key={'brl'} value={'brl'}>brl</option>
                                <option key={'usd'} value={'usd'}>usd</option>
                                <option key={'eur'} value={'eur'}>eur</option>
                                <option key={'gbp'} value={'gbp'}>gbp</option>
                            </select>
                        </div>

                    </div>

                    <div className={styleNews.propContainerContent}>
                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Preço BRL`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Preço BRL`} value={pricesValueInput['BRL']} onChange={(e) => handlePrices(e, 'BRL')} min="0" />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Preço USD`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Preço USD`} value={pricesValueInput['USD']} onChange={(e) => handlePrices(e, 'USD')} min="0" />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Preço EUR`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Preço EUR`} value={pricesValueInput['EUR']} onChange={(e) => handlePrices(e, 'EUR')} min="0" />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Preço GBP`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Preço GBP`} value={pricesValueInput['GBP']} onChange={(e) => handlePrices(e, 'GBP')} min="0" />
                        </div>
                    </div>

                    <div className={styleNews.propContainerContent}>
                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Data de Entrada`}</span>
                            <input className={styleNews.modalInput} type="datetime-local" placeholder={`Data de Entrada`} value={startDateInput} onChange={(e) => setStartDateInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Data de Saída`}</span>
                            <input className={styleNews.modalInput} type="datetime-local" placeholder={`Data de Saída`} value={endDateInput} onChange={(e) => setEndDateInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Descrição`}</span>
                            <input className={styleNews.modalInput} type="text" placeholder={`Descrição`} value={descInput} onChange={(e) => setDescInput(e.target.value)} />
                        </div>
                    </div>

                    <div className={styleNews.propContainerContent}>
                        <div className={styleNews.generalContainerInput} style={{ overflowY: 'hidden' }}>
                            <span className={styleTabs.modalImgLabel}>{`Imagem BRL *`}</span>
                            <input className={styleNews.inputFileHide} id="customFileInput1" type='file' accept=".png" onChange={(e) => handleImg(e, 'BRL')} />

                            <div className={styleNews.customFileInputContainer}>
                                <label htmlFor="customFileInput1" className={styleNews.customFileButton}>
                                    Escolher arquivo
                                </label>
                                {imgValueInput['BRL'] ?
                                    (<p className={styleNews.fileName}>{`${imgValueInput['BRL'].name}`}</p>)
                                    :
                                    (<p className={styleNews.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                }
                            </div>
                        </div>

                        <div className={styleNews.generalContainerInput} style={{ overflowY: 'hidden' }}>
                            <span className={styleTabs.modalImgLabel}>{`Imagem USD *`}</span>
                            <input className={styleNews.inputFileHide} id="customFileInput2" type='file' accept=".png" onChange={(e) => handleImg(e, 'USD')} />

                            <div className={styleNews.customFileInputContainer}>
                                <label htmlFor="customFileInput2" className={styleNews.customFileButton}>
                                    Escolher arquivo
                                </label>
                                {imgValueInput['USD'] ?
                                    (<p className={styleNews.fileName}>{`${imgValueInput['USD'].name}`}</p>)
                                    :
                                    (<p className={styleNews.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                }
                            </div>
                        </div>

                        <div className={styleNews.generalContainerInput} style={{ overflowY: 'hidden' }}>
                            <span className={styleTabs.modalImgLabel}>{`Imagem EUR *`}</span>
                            <input className={styleNews.inputFileHide} id="customFileInput3" type='file' accept=".png" onChange={(e) => handleImg(e, 'EUR')} />

                            <div className={styleNews.customFileInputContainer}>
                                <label htmlFor="customFileInput3" className={styleNews.customFileButton}>
                                    Escolher arquivo
                                </label>
                                {imgValueInput['EUR'] ?
                                    (<p className={styleNews.fileName}>{`${imgValueInput['EUR'].name}`}</p>)
                                    :
                                    (<p className={styleNews.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                }
                            </div>
                        </div>

                        <div className={styleNews.generalContainerInput} style={{ overflowY: 'hidden' }}>
                            <span className={styleTabs.modalImgLabel}>{`Imagem GBP *`}</span>
                            <input className={styleNews.inputFileHide} id="customFileInput4" type='file' accept=".png" onChange={(e) => handleImg(e, 'GBP')} />

                            <div className={styleNews.customFileInputContainer}>
                                <label htmlFor="customFileInput4" className={styleNews.customFileButton}>
                                    Escolher arquivo
                                </label>
                                {imgValueInput['GBP'] ?
                                    (<p className={styleNews.fileName}>{`${imgValueInput['GBP'].name}`}</p>)
                                    :
                                    (<p className={styleNews.fileName}>{`Nenhum Arquivo selecionado...`}</p>)
                                }
                            </div>
                        </div>
                    </div>

                    <div className={styleNews.propContainerContent}>
                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Início Desconto *`}</span>
                            <input className={styleNews.modalInput} type="datetime-local" placeholder={`Início Desconto`} value={startDiscountInput} onChange={(e) => setStartDiscountInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Término Desconto *`}</span>
                            <input className={styleNews.modalInput} type="datetime-local" placeholder={`Término Desconto`} value={endDiscountInput} onChange={(e) => setEndDiscountInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Desconto *`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Desconto`} value={discountInput} min="0" onChange={(e) => setDiscountInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Limite por usuário *`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Limite por usuário`} value={limitInput} min="0" onChange={(e) => setLimitInput(e.target.value)} />
                        </div>
                    </div>
                </div>
            )
        },
        {
            id: 2,
            tabTitle: 'Conteúdo',
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px' }}>
                    <div style={{ width: '100%' }} className={styleTabs.modalTableContent}>
                        <div className={styleTabs.modalTableContentTop}>
                            <div className={styleTabs.modalTableContentTopInputs}>
                                <div className={styleTabs.modalTableContentTopSelect}>
                                    <span className={styleTabs.modalLabel}>{`Modelo`}</span>
                                    <Select
                                        classNamePrefix="select"
                                        onChange={handleEditSelectInput}
                                        value={selectInput}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="categories"
                                        options={modelList}
                                        styles={customStyles}
                                        placeholder={"Adicionar Modelo"}
                                    />
                                </div>
                                <div className={styleTabs.modalTableContentTopInput}>
                                    <span className={styleTabs.modalLabel}>{`Moedas`}</span>
                                    <input style={{ margin: 0 }} className={styleTabs.modalInput} min={0} type="number" placeholder={`Moedas`} value={goldInput} onChange={(e) => setGoldInput(e.target.value)} />
                                </div>
                                <div className={styleTabs.modalTableContentTopInput}>
                                    <span className={styleTabs.modalLabel}>{`Fichas`}</span>
                                    <input style={{ margin: 0 }} className={styleTabs.modalInput} min={0} type="number" placeholder={`Fichas`} value={tokensInput} onChange={(e) => setTokensInput(e.target.value)} />
                                </div>


                            </div>

                        </div>
                        <div className={styleTabs.modalTableContentTopBtn}>
                            <div className={styleTabs.modalTableContentTopInput}>
                                <span className={styleTabs.modalLabel}>{`Configuração`}</span>
                                <input style={{ margin: 0 }} className={styleTabs.modalInput} type="text" placeholder={`Configuração`} value={configInput} onChange={(e) => setConfigInput(e.target.value)} />
                            </div>
                            <div className={styleTabs.modalTableContentTopInput}>
                                <span className={styleTabs.modalLabel}>{`Quantidade`}</span>
                                <input style={{ margin: 0 }} className={styleTabs.modalInput} type="number" min={1} placeholder={`Quantidade`} value={qntInput} onChange={(e) => setQntInput(e.target.value)} />
                            </div>
                            <div style={{ paddingTop: '15px' }}>
                                <Button disabled={!modelIsValid} text="Adicionar Item" onClick={handleAddItem} color="cyan" />
                            </div>

                        </div>

                        <div style={{ width: '100%' }}>
                            <BasicTable
                                onClickBtnItemInfo={'Excluir'}
                                hasButton={true}
                                onClickBtnItem={handleDeleteModel}
                                onClickBtnItemHeader={'Delete'}
                                items={modelItems}
                                headers={modelItemsHeaders}
                                height='20vh'
                            />
                        </div>
                    </div>

                    <div className={styleNews.propContainerContent}>
                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Moedas *`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Moedas *`} value={goldMetadataInput} min="0" onChange={(e) => setGoldMetadataInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Nível Cidadão *`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Nível Cidadão`} value={clMetadataInput} min="0" onChange={(e) => setClMetadataInput(e.target.value)} />
                        </div>

                        <div className={styleNews.generalContainerInput}>
                            <span className={styleTabs.modalLabel}>{`Meses de Vip *`}</span>
                            <input className={styleNews.modalInput} type="number" placeholder={`Meses de Vip`} value={vipMetadataInput} min="0" onChange={(e) => setVipMetadataInput(e.target.value)} />
                        </div>
                    </div>
                </div>
            )
        }
    )

    return (
        <div className='grid grid-cols-12 gap-4'>

            {verifyPackages && <div className='col-span-7'>
                <BasicTable
                    selectItems={packages.map((value) => {
                        return {
                            item: value,
                            selected: packageIds.includes(value.packageId)
                        }
                    })}
                    isSelectable={true}
                    onSelectItem={handleSelect}
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={packagesHeaders}
                    headers={packagesMomentHeaders}
                    slotHeader={<PackagesFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                    title='Buscar Pacotes'
                    onDetailRow={handlePackageDetails}
                    isLoading={isLoading && packages.length === 0}
                    slotActions={
                        <>
                            <Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />
                            <Button text="Criar Pacote" onClick={handleOpenCreateModal} color="cyan" />
                        </>
                    }
                    //items={packages}
                    slotFooter={
                        <div className='flex flex-row justify-between w-full'>
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(packages.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : packages.length} resultados</span>}
                            </div>
                            <div className='flex flex-row gap-[10px]'>
                                <Button disabled={false} text="Selecionar Todos" onClick={handleSelectAll} color="cyan" />
                                <Button disabled={packageIds.length == 0} text="Excluir Pacotes" onClick={handleOpenDeleteModal} color="cyan" />
                            </div>
                        </div>
                    }
                />
                <Modal
                    header={`Criar Pacote`}
                    isOpen={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className={styleNews.modalFooter}>
                            <span>{'* não obrigatório'}</span>
                            <Button disabled={!isFull} text="Salvar" onClick={handleCreatePackage} color="cyan" />
                        </div>}
                >
                    <div className={styleNews.modalCreateNewsContainer} style={{ width: '50vw', height: 'auto' }}>
                        <Tabs
                            tabs={tabs}
                        />
                    </div>
                </Modal>
                <Modal
                    header={`Atenção!`}
                    isOpen={isDelete}
                    onClose={handleCloseDeleteModal}
                >
                    <div className={styles.modalModLogContainer}>
                        <div className={styles.modalSlotHeaderTitle}>
                            <span className={styles.modalSubtitle}>{"Deseja apagar permanentemente o(s) pacote(s)?"}?</span>
                        </div>
                        <div className={styles.modalSlotHeaderBtn}>
                            <Button disabled={false} text="Sim" onClick={handleDeleteAll} color="cyan" />
                            <Button disabled={false} text="Não" onClick={handleCloseDeleteModal} color="cyan" />
                        </div>
                    </div>
                </Modal>
            </div>}
            <div className='col-span-5'>
                {verifyPackagesManage && selectedPackage.packageId && <Details item={selectedPackage} />}
            </div>

        </div>
    )
}
