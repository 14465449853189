import api, { apiNormalize } from './api'
import handleRequestError from 'helpers/apiErrorHandler';
import { privateChatDetailsMapping, privateChatMapping, privateChatMembersMapping } from 'domains/Moderation/PrivateChat/mapping';
import PrivateChat from 'domains/Moderation/PrivateChat/PrivateChat';
import PrivateChatMember from 'domains/Moderation/PrivateChat/PrivateChatMember';
import PrivateChatDetail from 'domains/Moderation/PrivateChat/PrivateChatDetail';

export async function getPrivateChats(params, skip) {
    try {
        const response = await api.get("/privateChats", { params: { ...params, skip } });
        const privateChats = apiNormalize.responseMapping(response.data, privateChatMapping)
        const normalizedPrivateChats = privateChats.map(privateChat => new PrivateChat(privateChat).get());
        return normalizedPrivateChats
    }
    catch (error) {
        handleRequestError(error)
    }

}

export const getPrivateChat = async (params) => {
    try {
        const response = await api.get('/privateChat/byId', { params })
        const privateChat = apiNormalize.responseMapping(response.data, privateChatMapping)
        const normalizedPrivateChat = privateChat.map(privateChat => new PrivateChat(privateChat).get());
        return normalizedPrivateChat
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedPrivateChatDetails = async (params) => {
    try {
        const response = await api.get('/privateChat/details', { params })
        const detail = privateChatDetailsMapping(response.data[0])
        const normalizedDetail = new PrivateChatDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const savePrivateChat = async (chatId, changes) => {
    try {
        const response = await api.post(`/privateChat/${chatId}/savePrivateChat`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deletePrivateChat = async (privateChatId) => {
    try {
        const response = await api.post(`/privateChat/${privateChatId}/deletePrivateChat`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export async function getPrivateChatMembers(params, skip) {
    try {
        const response = await api.get("/privateChat/getPrivateChatMembers", { params: { ...params, skip } });
        const privateChatMembers = apiNormalize.responseMapping(response.data, privateChatMembersMapping)
        const normalizedPrivateChatMembers = privateChatMembers.map(privateChatMember => new PrivateChatMember(privateChatMember).get());
        return normalizedPrivateChatMembers
    }
    catch (error) {
        handleRequestError(error)
    }
}

export const savePrivateChatMember = async (privateChatId, changes) => {
    try {
        const response = await api.post(`/privateChat/${privateChatId}/savePrivateChatMember`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const deletePrivateChatMember = async (privateChatId, changes) => {
    try {
        const response = await api.post(`/privateChat/${privateChatId}/deletePrivateChatMember`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}

export const editPrivateChatMember = async (privateChatId, changes) => {
    try {
        const response = await api.post(`/privateChat/${privateChatId}/editPrivateChatMember`, { changes })
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}