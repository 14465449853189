import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useDispatch } from "react-redux";
import { fetchPostsFilters } from "store/modules/posts/postsSlice";

export default function PostsFilter({ onFilterChange, defaultValues }) {
    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({ form: formData, isValid: isValid })

        let newValue= {};

        if (name == 'targetType') {
            let propName = {
                A: 'Postada no Perfil',
                P: 'Resposta à uma postagem',
            }
            newValue[name] = { name: propName[value], value };
        }
        else {
            newValue[name] = value;
        }

        fetchPostsFilters(dispatch, { ...defaultValues, ...newValue })
    }

    const fields = [
        {
            name: 'postId',
            label: 'ID da Postagem',
            value: defaultValues.postId,
            placeholder: 'ID da Postagem',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'avatarId',
            label: 'ID do Avatar',
            value: defaultValues.avatarId,
            placeholder: 'ID do Avatar',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'targetType',
            label: 'Tipo de Postagem',
            value: defaultValues.targetType.value ?? '',
            placeholder: 'Tipo de Postagem',
            type: 'select',
            items: [
                {
                    value: '',
                    name: 'Todos'
                },
                {
                    value: 'A',
                    name: 'Postada no Perfil'
                },
                {
                    value: 'P',
                    name: 'Resposta à uma postagem'
                },
            ],
            order: 'A-Z',
            gridClass: 'col-span-4',
        },
        {
            name: 'title',
            label: 'Título',
            value: defaultValues.title,
            placeholder: 'Título',
            type: 'text',
            validate: [],
            gridClass: 'col-span-4'
        },
        {
            name: 'dateFrom',
            label: 'De',
            value: defaultValues.dateFrom,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
        {
            name: 'dateTo',
            label: 'Até',
            value: defaultValues.dateTo,
            placeholder: '',
            type: 'date',
            gridClass: 'col-span-4'
        },
    ]

    return <DefaultForm fields={fields} onChange={handleSubmit} />
}