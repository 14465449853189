import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

// Autenticação
import Login from './Pages/auth/Login'

import Home from './Pages/Home'
import Dashboard from './Pages/Dashboard'
import Profile from './Pages/Profile'

import Activities from './Pages/Users/Activities'
import Points from './Pages/Users/Points'
import Email from './Pages/Users/Email'
import KudosLog from './Pages/Users/KudosLog'
import Invites from './Pages/Users/Invites'


import Moderation from './Pages/Moderation'
import AbuseReports from './Pages/Moderation'
import Infractions from './Pages/Moderation/Infractions'
import Events from './Pages/Moderation/Events'
import BlackList from './Pages/Moderation/BlackList'
import Manage from './Pages/Moderation/Manage'

import Revenue from './Pages/Revenue'

import ItemsCatalog from './Pages/Items/Catalog'
import Spin from './Pages/Items/Spin'
import Rewards from './Pages/Items/Rewards'

import SpacesCatalog from './Pages/Spaces/Catalog'

import Director from './Pages/Missions/Director'
import Tasks from './Pages/Missions/Tasks'

import Administration from './Pages/Administration'
import UserGroups from './Pages/Administration/UserGroups'
import Filters from './Pages/Administration/Filters'

import About from './Pages/About'
import Settings from './Pages/Settings'
import { isAuthenticated } from 'services/auth'
import useSMISocket from 'hooks/useSMISocket'

import { FriendsUrl } from 'RoutesPath/FriendUrl'
import { AvatarsUrl } from 'RoutesPath/AvatarsUrl'
import { IpsUrl } from 'RoutesPath/IpsUrl'
import { EmailUrl } from 'RoutesPath/EmailUrl'
import { ChatLogsUrl } from 'RoutesPath/ChatLogsUrl'
import { TransactionsUrl } from 'RoutesPath/TransactionsUrl'
import { ItemsUrl } from 'RoutesPath/ItemsUrl'
import { SpacesUrl } from 'RoutesPath/SpacesUrl'
import { MissionsUrl } from 'RoutesPath/MissionsUrl'
import { LogsUrl } from 'RoutesPath/LogsUrl'
import { UsersUrl } from 'RoutesPath/UsersUrl'
import { AbuseReportsUrl } from 'RoutesPath/AbuseReportsUrl'
import { InfractionsUrl } from 'RoutesPath/InfractionsUrl'
import Interstitials from 'Pages/Administration/Interstitials'
import { NewsUrl } from 'RoutesPath/NewsUrl'
import NewsCategories from 'Pages/News/Categories'
import { ModelItemsUrl } from 'RoutesPath/ModeItemslUrl'
import { AvatarNoNamesUrl } from 'RoutesPath/AvatarNoNamesUrl'
import { BlackListUrl } from 'RoutesPath/BlackListUrl'
import { StoreLogUrl } from 'RoutesPath/StoreLogUrl'
import { WidgetUrl } from 'RoutesPath/WidgetUrl'
import { EmailProviderBlacklistUrl } from 'RoutesPath/EmailProviderBlacklistUrl'
import { TournamentsUrl } from 'RoutesPath/TournamentsUrl'
import { PostsUrl } from 'RoutesPath/PostsUrl'
import { PackagesUrl } from 'RoutesPath/PackagesUrl'
import { WebSubscriptionUrl } from 'RoutesPath/WebSubscriptionUrl'
import Servers from 'Pages/Administration/Servers'
import { PrivateChatLogsUrl } from 'RoutesPath/PrivateChatLogsUrl'
import { PrivateChatsUrl } from 'RoutesPath/PrivateChatsUrl '

const ProtectedRoute = ({ element: Element }) => {
    const location = useLocation()

    if (!isAuthenticated()) {
        return <Navigate to="/auth/login" />
    }

    if (isAuthenticated() && location.pathname === '/auth/login') {
        return <Navigate to="/" />
    }

    return Element
}

function AppRoutes() {
    return (
        <Routes>
            <Route path="/auth/login" element={<ProtectedRoute element={<Login />} />} />

            <Route path="/" element={<ProtectedRoute element={<Home />} />} />
            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
            <Route path="/users" element={<ProtectedRoute element={<UsersUrl/>} />} />
            <Route path="/users/friends" element={<ProtectedRoute element={<FriendsUrl />} />} />
            <Route path="/users/avatars" element={<ProtectedRoute element={<AvatarsUrl />} />} />
            <Route path="/users/ips" element={<ProtectedRoute element={<IpsUrl />} />} />
            <Route path="/users/activities" element={<ProtectedRoute element={<Activities />} />} />
            <Route path="/users/points" element={<ProtectedRoute element={<Points />} />} />
            <Route path="/users/email" element={<ProtectedRoute element={<EmailUrl />} />} />
            <Route path="/users/kudos-log" element={<ProtectedRoute element={<KudosLog />} />} />
            <Route path="/users/invites" element={<ProtectedRoute element={<Invites />} />} />

            <Route path="/moderation" element={<ProtectedRoute element={<Moderation />} />} />
            <Route path="/moderation/abuse-reports" element={<ProtectedRoute element={<AbuseReportsUrl />} />} />
            <Route path="/moderation/chat-logs" element={<ProtectedRoute element={<ChatLogsUrl />} />} />
            <Route path="/moderation/private-chat-logs" element={<ProtectedRoute element={<PrivateChatLogsUrl />} />} />
            <Route path="/moderation/privateChats" element={<ProtectedRoute element={<PrivateChatsUrl />} />} />
            <Route path="/moderation/infractions" element={<ProtectedRoute element={<InfractionsUrl />} />} />
            <Route path="/moderation/avatarNoNames" element={<ProtectedRoute element={<AvatarNoNamesUrl />} />} />
            <Route path="/moderation/events" element={<ProtectedRoute element={<Events />} />} />
            <Route path="/moderation/blackList" element={<ProtectedRoute element={<BlackListUrl />} />} />
            <Route path="/moderation/emailProviderBlackList" element={<ProtectedRoute element={<EmailProviderBlacklistUrl />} />} />
            <Route path="/moderation/log/store" element={<ProtectedRoute element={<StoreLogUrl />} />} />
            <Route path="/moderation/manage" element={<ProtectedRoute element={<Manage />} />} />

            <Route path="/revenue" element={<ProtectedRoute element={<Revenue />} />} />
            <Route path="/revenue/transactions" element={<ProtectedRoute element={<TransactionsUrl />} />} />

            <Route path="/items" element={<ProtectedRoute element={<ItemsUrl />} />} />
            <Route path="/items/catalog" element={<ProtectedRoute element={<ItemsCatalog />} />} />
            <Route path="/items/model" element={<ProtectedRoute element={<ModelItemsUrl />} />} />
            <Route path="/items/widget" element={<ProtectedRoute element={<WidgetUrl />} />} />
            <Route path="/items/spin" element={<ProtectedRoute element={<Spin />} />} />
            <Route path="/items/rewards" element={<ProtectedRoute element={<Rewards />} />} />

            <Route path="/packages" element={<ProtectedRoute element={<PackagesUrl />} />} />

            <Route path="/spaces" element={<ProtectedRoute element={<SpacesUrl />} />} />
            <Route path="/spaces/catalog" element={<ProtectedRoute element={<SpacesCatalog />} />} />

            <Route path="/missions" element={<ProtectedRoute element={<MissionsUrl />} />} />
            <Route path="/missions/director" element={<ProtectedRoute element={<Director />} />} />
            <Route path="/missions/tasks" element={<ProtectedRoute element={<Tasks />} />} />

            <Route path="/administration" element={<ProtectedRoute element={<Administration />} />} />
            <Route path="/administration/groups" element={<ProtectedRoute element={<UserGroups />} />} />
            <Route path="/administration/webSubscriptions" element={<ProtectedRoute element={<WebSubscriptionUrl />} />} />
            <Route path="/administration/servers" element={<ProtectedRoute element={<Servers />} />} />
            <Route path="/administration/interstitials" element={<ProtectedRoute element={<Interstitials />} />} />
            <Route path="/administration/logs" element={<ProtectedRoute element={<LogsUrl />} />} />
            <Route path="/administration/filters" element={<ProtectedRoute element={<Filters />} />} />

            <Route path="/about" element={<ProtectedRoute element={<About />} />} />
            <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />

            <Route path="/news" element={<ProtectedRoute element={<NewsUrl />} />} />
            <Route path="/news/categories" element={<ProtectedRoute element={<NewsCategories />} />} />

            <Route path="/tournaments" element={<ProtectedRoute element={<TournamentsUrl />} />} />

            <Route path="/posts" element={<ProtectedRoute element={<PostsUrl />} />} />

            {/* <Route path="/users/:id" element={ <Users /> } /> */}
        </Routes>
    )
}

export default AppRoutes
