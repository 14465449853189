import PrivateChatLog from 'domains/Moderation/PrivateChatLog/PrivateChatLog';
import api, { apiNormalize } from './api'
import handleRequestError from 'helpers/apiErrorHandler';
import { privateChatLogsMapping } from 'domains/Moderation/PrivateChatLog/mapping';

export async function getPrivateChatLogs(params, skip){
    console.log(params)
    const response = await api.get("/privateChatMessages", {params:{...params, skip}});
    const chatLogs = apiNormalize.responseMapping(response.data, privateChatLogsMapping)
    const normalizedIChatLogs = chatLogs.map(chatLog => new PrivateChatLog(chatLog).get());
    return normalizedIChatLogs
}