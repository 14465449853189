class AvatarAttribute {
    constructor({ attributeType, value }) {
        this.attributeType = attributeType
        this.value = value
    }

    get() {
        return ({
            attributeType: this.attributeType,
            value: this.value,
        })
    }
}

export default AvatarAttribute