import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedPostDetails } from "store/modules/posts/selectedPostDetailSlice";

export function UsePost(item){
    const tournament = useSelector((state) => {
        return (state.selectedPostDetails.details) ? state.selectedPostDetails.details : {tableProperties: []}
    })

    const dispatch = useDispatch()

    useEffect(()=>{
        fetchSelectedPostDetails(dispatch, { postId: item.postId, targetType: item.targetType });
    }, [item])

    return tournament
}