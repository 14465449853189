import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import BasicTable from 'components/BasicTable';
import Button from 'components/form/Button';
import Tabs from 'components/Tabs';
import Modal from 'components/Modal';
import style from 'styles/UserTabs.module.css'
import styles from 'styles/Search.module.css'
import { toast } from 'react-toastify';

import { UsePost } from './postSearch';
import { fetchPosts, fetchPostsFilters } from 'store/modules/posts/postsSlice';
import { deletePost } from 'services/postsService';
import { cleanPostForDetails, selectPostForDetails } from 'store/modules/posts/selectedPostSlice';
import { selectAvatarForDetails } from 'store/modules/users/avatars/selectedAvatarSlice';
import { useNavigate } from 'react-router-dom';


export default function Details({ item }) {
    const postsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const navigate = useNavigate();

    const firstPostData = UsePost(item);
    const [tournamentData, setTournamentData] = useState(firstPostData)
    const [isDeletePost, setIsDeletePost] = useState(false);


    useEffect(() => {
        let firstPostDataCopy = { ...firstPostData }
        setTournamentData(firstPostDataCopy)

    }, [firstPostData])

    const dispatch = useDispatch()

    const handleSelectRow = async (item) => {
        if (item.name == 'ID Perfil no qual foi postado') {
            dispatch(selectAvatarForDetails({ avatarId: item.value }))
            navigate(`/users/avatars?avatarId=${item.value}`)
        }
        else if (item.name == "ID Post Respondido") {
            dispatch(cleanPostForDetails())
            await fetchPosts(dispatch, {postId: item.value}, 0);
           dispatch(selectPostForDetails({ postId: item.value }))
           navigate(`/posts?postId=${item.value}`)
        }
    }

    const handleDeletePost = async () => {
        try {
            await deletePost(item.postId)

            await fetchPosts(dispatch, {}, 0);
            dispatch(cleanPostForDetails())


            handleCloseDeletePostModal()
            toast.success('Post Removido com Sucesso!')
        }
        catch {
            toast.error('Erro ao Remover Post!')
        }
    }

    const handleOpenDeletePostModal = () => {
        setIsDeletePost(true)
    }

    const handleCloseDeletePostModal = () => {
        setIsDeletePost(false)
    }


    const tabs = []

    tabs.push(
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>
                    <BasicTable
                        onSelectRow={handleSelectRow}
                        headers={postsHeaders}
                        items={tournamentData.tableProperties}
                        height='55vh'
                    />
                </>
            )
        }
    )


    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{item.title}</b>
                            <p>{item.postId}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: "10px" }}>
                            {<Button text="Excluir" color="cyan" onClick={handleOpenDeletePostModal} />}
                        </div>
                    </div>
                )}
            />
            <Modal
                header={`Atenção!`}
                isOpen={isDeletePost}
                onClose={handleCloseDeletePostModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja apagar permanentemente a postagem?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeletePost} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeletePostModal} color="cyan" />
                    </div>
                </div>
            </Modal>

        </div>
    )
}
