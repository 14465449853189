const privateChatLogsMapping = (item) => ({
    messageId: item.messageId ?? null,
    message: item.message ?? null,
    date: item.date ?? null,
    chatId: item.chatId ?? null,
    chatName: item.chatName ?? null,
    avatarId: item.avatarId ?? null,
    avatarInstance: item.avatarInstance ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarIcon: (!item.avatarFirstName || !item.avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.avatarIcon}`,
    headPostfix: item.headPostfix ?? null,
})

export{privateChatLogsMapping}