import ChatLogs from "Pages/Moderation/ChatLogs";
import PrivateChatLogs from "Pages/Moderation/PrivateChatLogs";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchPrivateChatLogsFilters } from "store/modules/moderation/privateChatLogs/privateChatLogsSlice";

export function PrivateChatLogsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const avatarId = searchParams.get("avatarId");
    const chatId = searchParams.get("chatId");
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");
    const messageText = searchParams.get("messageText");
    

    const dispatch = useDispatch()

    if (avatarId || chatId || dateFrom || dateTo || messageText) {
        fetchPrivateChatLogsFilters(dispatch, { avatarId, chatId, dateFrom, dateTo, messageText })
        return <PrivateChatLogs avatarId={avatarId ?? null} chatId={chatId ?? null}  dateFrom={dateFrom ?? null} dateTo={dateTo ?? null} messageText={messageText ?? null}/>;
    }
    else{
        return <PrivateChatLogs/>;
    }

}