import DefaultForm from "components/form/DefaultForm"
import { uuidMask } from "helpers/masks"
import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { fetchPrivateChatsFilters } from "store/modules/moderation/privateChats/privateChatsSlice";

export default function PrivateChatFilter({onFilterChange, defaultValues}){

    const dispatch = useDispatch();

    const handleSubmit = (formData, isValid, name, value) => {
        onFilterChange({form: formData, isValid: isValid })
        
        let newValue= {};
        newValue[name] = value;
        fetchPrivateChatsFilters(dispatch, {...defaultValues, ...newValue})
    }

    const fields = [
        {
            name: 'chatId',
            label: 'ID do Chat',
            value: defaultValues.chatId,
            placeholder: 'ID do Chat',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'chatName',
            label: 'Nome do Chat',
            value: defaultValues.chatName,
            placeholder: 'Nome do Chat',
            type: 'text',
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'adminId',
            label: 'ID de algum Admin',
            value: defaultValues.adminId,
            placeholder: 'ID de algum Admin',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        },
        {
            name: 'memberId',
            label: 'ID de algum Membro',
            value: defaultValues.memberId,
            placeholder: 'ID de algum Membro',
            type: 'text',
            mask: uuidMask,
            validate: [],
            gridClass: 'col-span-6'
        }, 
    ]

    return (
        <>
            <DefaultForm fields={ fields } onChange={handleSubmit} />
        </>
    )
}