import Avatar from "domains/User/Avatars/Avatar";
import api, { apiNormalize } from "./api";
import { avatarDetailsMapping, avatarOptionsMapping, avatarPetsMapping, avatarProfileDetailsMapping, avatarXpLogsMapping, avatarXpMapping, avatarsMapping, avatarAttributeMapping } from "domains/User/Avatars/mapping";
import AvatarDetail from "domains/User/Avatars/AvatarDetail";
import handleRequestError from "helpers/apiErrorHandler";
import AvatarProfileDetail from "domains/User/Avatars/AvatarProfileDetail";
import AvatarOption from "domains/User/Avatars/AvatarOption";
import AvatarXpLogs from "domains/User/Avatars/AvatarXpLogs";
import AvatarXp from "domains/User/Avatars/AvatarXp";
import AvatarPets from "domains/User/Avatars/AvatarPets";
import AvatarAttribute from "domains/User/Avatars/AvatarAttribute";

export async function getAvatars(params, skip) {
    const response = await api.get("/avatars", { params: { ...params, skip } });
    const avatars = apiNormalize.responseMapping(response.data, avatarsMapping)
    const normalizedAvatars = avatars.map(avatar => new Avatar(avatar).get());
    return normalizedAvatars
}

export const getSelectedAvatarDetails = async (params) => {
    try {
        const response = await api.get('/avatars/details', { params })
        const detail = avatarDetailsMapping(response.data)
        const normalizedDetail = new AvatarDetail(detail).get()
        return normalizedDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const getSelectedAvatarProfileDetails = async (params) => {
    try {
        const response = await api.get('/avatars/profileDetails', { params })
        const profileDetail = avatarProfileDetailsMapping(response.data)
        const normalizedprofileDetail = new AvatarProfileDetail(profileDetail).get()
        return normalizedprofileDetail
    } catch (error) {
        handleRequestError(error)
    }
}

export const getAvatarOptions = async (params) => {
    try {
        const response = await api.get('/avatars/options', { params: { ...params} })
        const options = apiNormalize.responseMapping(response.data, avatarOptionsMapping)
        const normalizedOptions = options.map(avatar => new AvatarOption(avatar).get());
        return normalizedOptions[0]
    } catch (error) {
        handleRequestError(error)
    }
}

export const saveAvatarProfileField = async (avatarId, changes) => {
    try{
        const response = await api.post(`/avatar/${avatarId}/saveAvatarProfileField`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const saveAvatar = async (avatarId, changes) => {
    try{
        const response = await api.post(`/avatar/${avatarId}/saveAvatar`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const saveAvatarProfileText = async (avatarId, changes) => {
    try{
        const response = await api.post(`/avatar/${avatarId}/saveAvatarProfileText`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export async function getXpLogs(params, skip, userId, avatarId){
    const response = await api.get("/avatar/getXpLogs", {params:{...params, skip, userId, avatarId}});
    const xpLogs = apiNormalize.responseMapping(response.data, avatarXpLogsMapping)
    const normalizedXpLogs = xpLogs.map(xpLogs => new AvatarXpLogs(xpLogs).get());
    return normalizedXpLogs
}

export const getAvatarXp = async (avatarId) => {
    try{
        const response = await api.get(`/avatar/${avatarId}/getAvatarXp`)
        const xps = apiNormalize.responseMapping(response.data, avatarXpMapping)
        const normalizedXps = xps.map(xp => new AvatarXp(xp).get());
        return normalizedXps
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const getAvatarAttribute = async (avatarId) => {
    try{
        const response = await api.get(`/avatar/${avatarId}/getAvatarAttribute`)
        const attributes = apiNormalize.responseMapping(response.data, avatarAttributeMapping)
        const normalizedAttributes = attributes.map(attribute => new AvatarAttribute(attribute).get());
        return normalizedAttributes
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const saveAvatarXp = async (avatarId, changes) => {
    try{
        const response = await api.post(`/avatar/${avatarId}/saveAvatarXp`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export const saveAvatarAttribute = async (avatarId, changes) => {
    try{
        const response = await api.post(`/avatar/${avatarId}/saveAvatarAttribute`, {changes})
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}

export async function getPets(params, skip){
    const response = await api.get("/avatar/getPets", {params:{...params, skip}});
    const pets = apiNormalize.responseMapping(response.data, avatarPetsMapping)
    const normalizedPets = pets.map(pet => new AvatarPets(pet).get());
    return normalizedPets
}


export const deletePet = async (petId) => {
    try{
        const response = await api.post(`/avatar/${petId}/deletePet`)
        return response.data
    }
    catch(error){
        handleRequestError(error)
        throw error;
    }
}