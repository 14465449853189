const postsMapping = (item) => ({
    postId: item._id ?? null,
    avatarId: item.avatar_id ?? null,
    targetType: item.targetType ?? null,
    title: item.title ?? null,
    date: item.createdAt ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: item.avatarIcon ?? null,
})

const postsDetailsMapping = (item) => ({
    postId: item._id ?? null,
    avatarId: item.avatar_id ?? null,
    targetType: item.targetType ?? null,
    title: item.title ?? null,
    date: item.createdAt ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: item.avatarIcon ?? null,
    update: item.updatedAt ?? null,

    targetId: item.target ?? null,
    targetAvatarFirstName: item.targetAvatarFirstName ?? null,
    targetAvatarLastName: item.targetAvatarLastName ?? null,
    targetAvatarNameInstance: item.targetAvatarNameInstance ?? null,
    targetPostTitle: item.targetPostTitle ?? null
})

export {postsMapping, postsDetailsMapping}