import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../styles/Search.module.css'
import moment from 'moment'
import { fetchPrivateChatLogs } from 'store/modules/moderation/privateChatLogs/privateChatLogsSlice'
import ChatLogsFilter from './Partials/ChatLogsFilter'

export default function PrivateChatLogs({ avatarId, chatId, dateFrom, dateTo, messageText }) {
    const iconObject = { key: 'avatarIcon' }

    const [privateChatLogsHeaders, setPrivateChatLogsHeaders] = useState([
        { title: 'ID', key: 'smallId', copy: 'messageId', appear: true },
        { title: 'Data', key: 'date', appear: true },
        { title: 'Mensagem', key: 'message', appear: true },
        { title: 'Nome do Avatar', key: 'avatarName', copy: 'avatarId', icon: () => iconObject, appear: true },
        { title: 'Nome do Chat', key: 'chatName', copy: 'chatId', appear: true },
    ])

    const [privateChatLogsMomentHeaders, setPrivateChatLogsMomentHeaders] = useState([...privateChatLogsHeaders]);

    const handleAppearHeader = (item) => {
        setPrivateChatLogsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setPrivateChatLogsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }



    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const privateChatLogs = useSelector((state) => {
        return Array.isArray(state.privateChatLogs.data) ? state.privateChatLogs.data : []
    })

    const skip = useSelector((state) => {
        return (state.privateChatLogs.currentSkip) ? state.privateChatLogs.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.privateChatLogs.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.privateChatLogs.filters) ? state.privateChatLogs.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [privateChatLogs]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (avatarId || chatId || dateFrom || dateTo || messageText) {
            setLoading(true)
            fetchPrivateChatLogs(dispatch, { avatarId, chatId, dateFrom: moment(dateFrom).utc().format(), dateTo: moment(dateTo).utc().format(), messageText }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.avatarId) query.append('avatarId', filter.form.avatarId);
        if (filter.form.chatId) query.append('chatId', filter.form.chatId);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        if (filter.form.messageText) query.append('messageText', filter.form.messageText);
        window.history.pushState({}, '', `/moderation/private-chat-logs?${query.toString()}`);
        fetchPrivateChatLogs(dispatch, { ...filter.form, fromAbuseReport: false, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() }, 0);

        console.log({ ...filter.form, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() })
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchPrivateChatLogs(dispatch, { ...filter.form, fromAbuseReport: false, dateTo: moment(filter.form.dateTo).utc().format(), dateFrom: moment(filter.form.dateFrom).utc().format() }, skip + 200);
    })

    console.log(hasMore)
    return (
        <>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12'>
                    <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                        <ChatLogsFilter onFilterChange={handleSetFilter} defaultValues={filters} />
                    </div>
                </div>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={privateChatLogsHeaders}
                        headers={privateChatLogsMomentHeaders}
                        title='Buscar Mensagens Privadas'
                        isLoading={isLoading && privateChatLogs.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={privateChatLogs}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading || privateChatLogs.length == 0} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(privateChatLogs.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : privateChatLogs.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    )
}
