const privateChatMapping = item => ({
    chatId: item._id ?? null,
    chatName: item.chatName ?? null,
})

const privateChatDetailsMapping = item => ({
    chatId: item._id ?? null,
    chatName: item.chatName ?? null,
    color: item.color ?? null
})

const privateChatMembersMapping = (item) => ({
    avatarId: item.avatarId ?? null,
    avatarFirstName: item.avatarFirstName ?? null,
    avatarLastName: item.avatarLastName ?? null,
    avatarNameInstance: item.avatarNameInstance ?? null,
    avatarIcon: (!item.avatarFirstName || !item.avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${item.avatarIcon}`,
    avatarRole: item.avatarRole ?? null,
})

export {privateChatMapping, privateChatDetailsMapping, privateChatMembersMapping}