import { createSlice } from '@reduxjs/toolkit'
import { getPosts } from 'services/postsService';

const selectedPostSlice = createSlice({
    name: 'selectedPost',
    initialState: {},
    reducers: {
        setSelectedPost: (state, action) => {
            let post;

            if (Array.isArray(action.payload) && action.payload.length > 0) {
                post = action.payload[0]
            } else {
                post = {}
            }            

            return post
        },
    },
})

export const selectPostForDetails = (params) => async (dispatch) => {
    try {
        const selectedPost = await getPosts(params, 0)
        dispatch(setSelectedPost(selectedPost))
    } catch (error) {
        console.error('Erro ao carregar post:', error)
    }
}

export const cleanPostForDetails = () => async (dispatch) => {
    dispatch(setSelectedPost([]))
}

export const { setSelectedPost } = selectedPostSlice.actions
export default selectedPostSlice.reducer
