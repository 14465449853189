import moment from "moment"
import { memo } from "react"

class Server {
    constructor({ name, ip, port, performance, avatarsOnline, spacesOnline, version }) {
        this.name = name
        this.ip = ip
        this.port = port
        this.performance = performance;
        this.avatarsOnline = avatarsOnline;
        this.spacesOnline = spacesOnline;
        this.version = version;
    }

    get() {
        return {
            name: this.name,
            ip: this.ip,
            port: this.port,
            version: this.version,
            avatarsOnline: this.avatarsOnline,
            spacesOnline: this.spacesOnline,
            cpuUsage: this.performance.cpuUsage + "%",
            memoryUsage: Number(this.performance.memoryUsage.rss).toFixed(2) + "MB",
            receivedBytes: this.performance.networkUsage.receivedBytes + "MB",
            sentBytes: this.performance.networkUsage.transmittedBytes + "MB",
        }
    }
}

export default Server