import Posts from "Pages/Posts";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchPostsFilters } from "store/modules/posts/postsSlice";

export function PostsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const postId = searchParams.get("postId");
    const avatarId = searchParams.get("avatarId");
    const targetType = searchParams.get("targetType")
    const title = searchParams.get("title")
    const dateFrom = searchParams.get("dateFrom");
    const dateTo = searchParams.get("dateTo");

    let targetTypePropName = {
        A: 'Postada no Perfil',
        P: 'Resposta à uma postagem',
    }

    const dispatch = useDispatch()

    if (postId || avatarId || targetType || title || dateFrom || dateTo) {
        fetchPostsFilters(dispatch, {postId, avatarId, targetType: targetType ? {name: targetTypePropName[type], value: targetType} : '', title, dateFrom, dateTo })
        return <Posts postId={postId ?? null} avatarId={avatarId ?? null} targetType={targetType ?? null} title={title ?? null} dateFrom={dateFrom ?? null} dateTo={dateTo ?? null}/>;
    }
    else{
        return <Posts/>;
    }

}