import { getPrivateChatMembers } from "services/privateChatService";

const { createSlice } = require("@reduxjs/toolkit");

const privateChatMembersSlice = createSlice({
    name: "privateChatMembers",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
    },
    reducers: {
        setPrivateChatMembersData: (state, action) => {
            return { ...state, data: action.payload }
        },
        addPrivateChatMembersData: (state, action) => {
            return { ...state, data: [...state.data, ...action.payload] }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
    }
})

export async function fetchPrivateChatMembers(dispatch, params, skip) {
    try {
        const LIMIT = 200;
        const privateChatMembers = await getPrivateChatMembers(params, skip);
        dispatch(setCurrentSkip(skip));
        if (skip > 0) {
            dispatch(addPrivateChatMembersData(privateChatMembers))
        }
        else {
            dispatch(setPrivateChatMembersData(privateChatMembers));
        }

        if (privateChatMembers.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Membros', error)
    }
}

export const { setPrivateChatMembersData, addPrivateChatMembersData, setCurrentSkip, setHasMore } = privateChatMembersSlice.actions
export default privateChatMembersSlice.reducer