import Tabs from 'components/Tabs'
import React, { useEffect, useState } from 'react'
import { useSpace } from './spaceSearch';
import BasicTable from 'components/BasicTable';
import Modal from 'components/Modal';
import style from '../../../styles/UserTabs.module.css'
import styles from '../../../styles/Search.module.css'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/form/Button';
import { fetchSelectedSpaceDetails } from 'store/modules/spaces/selectedSpaceDetailSlice';
import { deleteSpaceMember, editSpaceMember, saveSpace, saveSpaceMember, saveSpaceSnapshot, saveSpaceThumbnail } from 'services/spaceService';
import { toast } from 'react-toastify';
import { fetchSpaceMembers } from 'store/modules/spaces/spaceMembersSlice';
import { Paths } from 'paths/Paths';
import SpaceChat from './spaceChat';
import useUserPermission from 'domains/Permissions/useUserPermission';
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode';

export default function Details({ item }) {
    const verifyEditSpace = useUserPermission(SMIPermissionsCode.SPACE_DETAILS_EDIT);
    const verifySpaceMembers = useUserPermission(SMIPermissionsCode.SPACE_MEMBERS_VIEW);
    const verifySpaceMembersEdit = useUserPermission(SMIPermissionsCode.SPACE_MEMBERS_EDIT);
    const verifySpaceChatLog = useUserPermission(SMIPermissionsCode.MOD_SPACE_CHATLOGS);
    //ABA PROPRIEDADES

    const spaceHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstSpaceData = useSpace(item);
    const [spaceData, setSpaceData] = useState(firstSpaceData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstSpaceDataCopy = { ...firstSpaceData }
        setSpaceData(firstSpaceDataCopy)

        setOriginalValues({
            spaceId: firstSpaceData.spaceId,
            spaceName: firstSpaceData.spaceName,
            desc: firstSpaceData.desc,
            accessControlName: firstSpaceData.accessControl,
            showInPlacePanel: firstSpaceData.showInPlacePanel,
            instanciable: firstSpaceData.instanciable,
            maxVisitors: firstSpaceData.maxVisitors,
            password: firstSpaceData.password,
            type: firstSpaceData.type,
            forSaleName: firstSpaceData.forSale,
            salePriceTokens: firstSpaceData.salePriceTokens,
            salePriceGold: firstSpaceData.salePriceGold,
            avatarId: firstSpaceData.avatarId,
            modelId: firstSpaceData.modelId,
            modelName: firstSpaceData.modelName,
            avatarName: firstSpaceData.avatarName,
        })

        setChangedValues({
            spaceId: firstSpaceData.spaceId,
            spaceName: firstSpaceData.spaceName,
            desc: firstSpaceData.desc,
            accessControlName: firstSpaceData.accessControl,
            showInPlacePanel: firstSpaceData.showInPlacePanel,
            instanciable: firstSpaceData.instanciable,
            maxVisitors: firstSpaceData.maxVisitors,
            password: firstSpaceData.password,
            type: firstSpaceData.type,
            forSaleName: firstSpaceData.forSale,
            salePriceTokens: firstSpaceData.salePriceTokens,
            salePriceGold: firstSpaceData.salePriceGold,
            avatarId: firstSpaceData.avatarId,
            modelId: firstSpaceData.modelId,
            modelName: firstSpaceData.modelName,
            avatarName: firstSpaceData.avatarName,
        })

    }, [firstSpaceData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name != "ID do Espaço" && item.name != "Nome do Avatar" && item.name != "Nome do Modelo" && item.name != "Tipo") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let spaceDataCopy = { ...spaceData, tableProperties: [...spaceData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            for (let i = 0; i < spaceData.tableProperties.length; i++) {
                if (spaceData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    spaceDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    spaceDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                    console.log(changedValuesCopy)
                }
            }
            setChangedValues(changedValuesCopy)
            setSpaceData(spaceDataCopy);
        }
        if ((inputValue == '' && modalTitle == 'Senha') || (inputValue == '' && modalTitle == 'Descrição') || (inputValue == '' && modalTitle == 'Fonte de SnapShot do Espaço') || (inputValue == '' && modalTitle == 'Fonte da Thumbnail do Espaço')) {
            for (let i = 0; i < spaceData.tableProperties.length; i++) {
                if (spaceData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    spaceDataCopy.tableProperties[i] = { name: currentItem.name, value: "-", key: currentItem.key };
                    spaceDataCopy[currentItem.key] = "-";
                    changedValuesCopy[currentItem.key] = "-";
                }
            }
            setChangedValues(changedValuesCopy)
            setSpaceData(spaceDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();
        console.log(changedValues)

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setSpaceData(firstSpaceData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyAccessControl = {
                'Aberto': 'O',
                'Apenas membros VIP': 'V',
                'Protegido por Senha': 'P',
                'Membros': 'M',
            }

            const propertyForSale = {
                'Não a venda': 'N',
                'Instance Sale': 'I',
                'Model Sale': 'M',
            }

            const propertyApi = {
                spaceName: 'name',
                desc: 'desc',
                accessControlName: 'accessControl',
                showInPlacePanel: 'showInPlacePanel',
                instanciable: 'instanciable',
                maxVisitors: 'maxVisitors',
                password: 'password',
                forSaleName: 'forSale',
                salePriceTokens: 'salePriceTokens',
                salePriceGold: 'salePriceGold',
                avatarId: 'avatar_id',
                modelId: 'modelId',
            }

            let changes = {}


            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                console.log(originalValues)
                console.log(changedValues)
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            if (changes['accessControl']) {
                changes['accessControl'] = propertyAccessControl[changes['accessControl']]
            }

            if (changes['forSale']) {
                changes['forSale'] = propertyForSale[changes['forSale']]
            }

            if (changes['showInPlacePanel']) {
                changes['showInPlacePanel'] == 'Sim' ? changes['showInPlacePanel'] = true : changes['showInPlacePanel'] = false
            }

            if (changes['instanciable']) {
                changes['instanciable'] == 'Sim' ? changes['instanciable'] = true : changes['instanciable'] = false
            }

            if (changes['desc'] == '-') {
                changes['desc'] = null
            }

            if (changes['password'] == '-') {
                changes['password'] = null
            }

            await saveSpace(item.id, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            await fetchSelectedSpaceDetails(dispatch, { id: item.id });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }

    }

    //ABA MEMBROS

    const avatarIconObject = { key: 'avatarIcon' }

    const spaceMembersHeaders = [
        { title: 'Papel', key: 'avatarRole' },
        { title: 'Avatar', key: 'avatarName', copy: 'avatarId', icon: () => avatarIconObject, detailable: true },
    ]

    const [spaceMembersIsLoading, setSpaceMembersLoading] = useState(false);

    const spaceMembers = useSelector((state) => {
        return Array.isArray(state.spaceMembers.data) ? state.spaceMembers.data : []
    })

    const spaceMembersSkip = useSelector((state) => {
        return (state.spaceMembers.currentSkip) ? state.spaceMembers.currentSkip : 0
    })
    const spaceMembersHasMore = useSelector((state) => {
        return Boolean(state.spaceMembers.hasMore)
    })

    const [roleInputValue, setRoleInputValue] = useState(0);
    const [idInputValue, setIdInputValue] = useState('');
    const [isModalAddMemberOpen, setIsModalAddMemberOpen] = useState(false)

    const [isModalEditMemberOpen, setIsModalEditMemberOpen] = useState(false)
    const [avatarRole, setAvatarRole] = useState(0);
    const [avatarId, setAvatarId] = useState("");
    const [originalAvatarRole, setOriginalAvatarRole] = useState(0);

    useEffect(() => {
        setSpaceMembersLoading(false)
    }, [spaceMembers]);

    useEffect(() => {
        setSpaceMembersLoading(true)
        fetchSpaceMembers(dispatch, { spaceId: item.id }, 0);
    }, [item])

    const spaceMembersHandleLoadMore = (() => {
        setSpaceMembersLoading(true);
        fetchSpaceMembers(dispatch, { spaceId: item.id }, skip + 200);
    })

    const handleOpenAddMemberModal = () => {
        setIsModalAddMemberOpen(true)
    }

    const handleCloseAddMemberModal = () => {
        setIdInputValue("");
        setRoleInputValue(0);
        setOriginalAvatarRole(0);
        setIsModalAddMemberOpen(false)
    }

    const handleOnChangeRoleInput = (event) => {
        setRoleInputValue(event.target.value)
    }

    const handleOnChangeIdInput = (event) => {
        setIdInputValue(event.target.value)
    }

    const saveMember = async () => {
        let check = false;
        for (let i = 0; i < spaceMembers.length; i++) {
            if (spaceMembers[i].avatarId == idInputValue) {
                check = true
            }
        }
        if (idInputValue != "") {
            try {
                if (!check) {


                    await saveSpaceMember(item.id, { 'avatar_id': idInputValue, 'role': roleInputValue });
                    setSpaceMembersLoading(true);
                    await fetchSpaceMembers(dispatch, { spaceId: item.id }, 0);
                    toast.success('Dados salvos com Sucesso!');
                }
                else {
                    toast.error('Avatar já é membro!');
                }
            }
            catch {
                toast.error('Erro ao salvar!');
            }
            handleCloseAddMemberModal();
        }
    }

    const handleOpenEditMemberModal = () => {
        setIsModalEditMemberOpen(true)
    }

    const handleCloseEditMemberModal = () => {
        setAvatarRole(0);
        setAvatarId("");
        setIsModalEditMemberOpen(false)
    }

    const handleDetailableMember = (member) => {
        const avatarRoleCode = {
            'Open': 1,
            'Collaborator': 2,
            'Member': 3,
        }

        setAvatarId(member.avatarId)
        setAvatarRole(avatarRoleCode[member.avatarRole]);
        setOriginalAvatarRole(avatarRoleCode[member.avatarRole]);
        handleOpenEditMemberModal();
    }

    const handleOnChangeEditRoleInput = (event) => {
        setAvatarRole(event.target.value)
    }

    const deleteMember = async () => {
        try {
            await deleteSpaceMember(item.id, { 'avatar_id': avatarId });
            setSpaceMembersLoading(true);
            await fetchSpaceMembers(dispatch, { spaceId: item.id }, 0);
            toast.success('Membro deletado com Sucesso!');
        }
        catch {
            toast.error('Erro ao Deletar!');
        }
        handleCloseEditMemberModal();

    }

    const editMember = async () => {
        if (avatarRole != originalAvatarRole) {
            try {
                await editSpaceMember(item.id, { 'avatar_id': avatarId, 'role': avatarRole });
                setSpaceMembersLoading(true);
                await fetchSpaceMembers(dispatch, { spaceId: item.id }, 0);
                toast.success('Membro Atualizado com Sucesso!');
            }
            catch {
                toast.error('Erro ao Atualizar Membro!');
            }
            handleCloseEditMemberModal();
        }
    }

    const handleFileSubmit = async (e) => {
        try {
            const file = e.target.files[0];
            if (modalTitle == "Fonte de SnapShot do Espaço") {
                await saveSpaceSnapshot(item.id, file);
            }
            else {
                await saveSpaceThumbnail(item.id, file);
            }
            await fetchSelectedSpaceDetails(dispatch, { id: item.id });
            toast.success('Imagem Salva com Sucesso!');
        }
        catch {
            toast.error('Erro ao Salvar Imagem!');
        }
        handleCloseModal()


    }

    //ABA BATEPAPO


    const tabs = []

    tabs.push({
        id: 1,
        tabTitle: 'Propriedades',
        content: (
            <>
                <BasicTable
                    round={false}
                    height='58.5vh'
                    onSelectRow={handleSelectRow}
                    headers={spaceHeaders}
                    items={spaceData.tableProperties}
                    slotHeader={(handleValueChange) ? "" :
                        <div className={style.header}>
                            <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                            <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                        </div>
                    }
                />
                {verifyEditSpace && <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                >
                    {(modalTitle != "Fonte de SnapShot do Espaço" && modalTitle != "Fonte da Thumbnail do Espaço") ?
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                            {modalTitle != "Controle de Acesso" ?
                                (modalTitle != 'Mostrar em Painel de Populares' && modalTitle != 'Instanciável') ?
                                    (modalTitle != 'A venda') ?
                                        (modalTitle != 'Custo em Fichas' && modalTitle != 'Custo em Moedas' && modalTitle != 'Máximo de Visitantes' && modalTitle != 'ID do Modelo') ?
                                            <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                            : <input className={style.modalInput} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                        :
                                        <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                            <option key={'1'} value={'Não a venda'}>Não a venda</option>
                                            <option key={'2'} value={'Instance Sale'}>Instance Sale</option>
                                            <option key={'3'} value={'Model Sale'}>Model Sale</option>
                                        </select>
                                    :
                                    <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                        <option key={'Sim'} value={'Sim'}>Sim</option>
                                        <option key={'Não'} value={'Não'}>Não</option>
                                    </select>
                                :
                                <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                    <option key={'1'} value={'Aberto'}>Aberto</option>
                                    <option key={'2'} value={'Apenas membros VIP'}>Apenas membros VIP</option>
                                    <option key={'3'} value={'Membros'}>Membros</option>
                                    <option key={'4'} value={'Protegido por Senha'}>Protegido por Senha</option>
                                </select>
                            }

                            <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                        </div>
                        :
                        <div className={style.modalImgContent}>
                            <img className={style.modalImg} src={Paths.getSpaceThumbnailURL(inputValue)} />
                            {modalTitle != "Fonte de SnapShot do Espaço" ?
                                <span className={style.modalImgLabel}>{`Nova Imagem para Thumbnail:`}</span>
                                :
                                <span className={style.modalImgLabel}>{`Nova Imagem para SnapShot:`}</span>
                            }
                            <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />

                        </div>

                    }
                </Modal>}
            </>
        )
    })

    if (verifySpaceMembers) {
        tabs.push({
            id: 2,
            tabTitle: 'Membros',
            content: (
                <>
                    <BasicTable
                        title={'Membros'}
                        round={false}
                        height='46.5vh'
                        headers={spaceMembersHeaders}
                        onDetailRow={handleDetailableMember}
                        items={spaceMembers}
                        isLoading={spaceMembersIsLoading && spaceMembers.length === 0}
                        slotActions={verifySpaceMembersEdit && <Button text="Adicionar Membro" color="cyan" onClick={handleOpenAddMemberModal} disabled={false} />}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!spaceMembersHasMore || spaceMembersIsLoading} text="Carregar Mais" onClick={spaceMembersHandleLoadMore} color="cyan" />
                                {(spaceMembers.length === 0) ? "" : <span>Exibindo {0}-{(spaceMembersHasMore) ? spaceMembersSkip + 200 : spaceMembers.length} resultados</span>}
                            </div>
                        }
                    />
                    {verifySpaceMembersEdit && <Modal
                        isOpen={isModalAddMemberOpen}
                        onClose={handleCloseAddMemberModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Papel`}</span>
                            <select value={roleInputValue} className={style.select} onChange={handleOnChangeRoleInput}>
                                <option key={'1'} value={1}>Officer</option>
                                <option key={'2'} value={2}>Collaborator</option>
                                <option key={'3'} value={3}>Member</option>
                            </select>
                            <span className={style.membersModalLabel}>{`ID do Avatar`}</span>
                            <input className={style.modalInput} type="text" placeholder={'ID do Avatar'} value={idInputValue} onChange={handleOnChangeIdInput} />

                            <button className={style.modalButton} onClick={saveMember}>Salvar</button>
                        </div>
                    </Modal>}
                    {verifySpaceMembersEdit && <Modal
                        isOpen={isModalEditMemberOpen}
                        onClose={handleCloseEditMemberModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Papel`}</span>
                            <select value={avatarRole} className={style.select} onChange={handleOnChangeEditRoleInput}>
                                <option key={'1'} value={1}>Officer</option>
                                <option key={'2'} value={2}>Collaborator</option>
                                <option key={'3'} value={3}>Member</option>
                            </select>

                            <div className={style.memberModalButtons}>
                                <button className={style.modalButton} onClick={editMember}>Alterar</button>
                                <button className={style.modalButton} onClick={deleteMember}>Excluir Membro</button>
                            </div>
                        </div>
                    </Modal>}
                </>
            )
        })
    }

    if (verifySpaceChatLog) {
        tabs.push({
            id: 3,
            tabTitle: 'BatePapo',
            content: (
                <SpaceChat space={item} />
            )
        })
    }

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm'>
                        <b>{item.spaceName}</b>
                        <p>{item.id}</p>
                    </div>
                )}
            />
        </div>
    )
}
