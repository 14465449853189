import { getPrivateChats } from "services/privateChatService";

const { createSlice } = require("@reduxjs/toolkit");

const privateChatsSlice = createSlice({
    name: "privateChats",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            chatId: '',
            chatName: '',
            adminId: '',
            memberId: ''
        }
    },
    reducers: {
        setPrivateChatsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addPrivateChatsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setPrivateChatsFilter: (state, action) => {
            return {...state, filters: action.payload}
        },
    }
})

export async function fetchPrivateChats(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const privateChats = await getPrivateChats(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addPrivateChatsData(privateChats))
        }
        else{
            dispatch(setPrivateChatsData(privateChats));
        }
        
        if(privateChats.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter Chats Privados', error)
        dispatch(setCurrentSkip(0));
        dispatch(setPrivateChatsData([]));
        dispatch(setHasMore(false));

    }
}

export async function fetchPrivateChatsFilters(dispatch, params){
        dispatch(setPrivateChatsFilter(params));
}

export const { setPrivateChatsData, setCurrentSkip, setHasMore, addPrivateChatsData, setPrivateChatsFilter } = privateChatsSlice.actions
export default privateChatsSlice.reducer