
import api, { apiNormalize } from './api'
import handleRequestError from 'helpers/apiErrorHandler';
import { serverMappings } from 'domains/Administration/Servers/mapping';
import Server from 'domains/Administration/Servers/server';

export async function getServers(params) {
    try {
        const response = await api.get("/servers", { params: { ...params } });
        const storeLogs = apiNormalize.responseMapping(response.data.servers, serverMappings)
        const normalizedStoreLogs = storeLogs.map(storeLog => new Server(storeLog).get());
        return normalizedStoreLogs
    }

    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}

export async function createServer() {
    try {
        const response = await api.post("/servers/createServer");
        return response.data
    }

    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}

export async function deleteServer(ids) {
    try {
        const response = await api.post("/servers/deleteServer", { ids: ids });
        return response.data
    }

    catch (error) {
        console.log(error)
        handleRequestError(error)
    }
}