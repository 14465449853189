
import { postsDetailsMapping, postsMapping } from "domains/Posts/mapping";
import api, { apiNormalize } from "./api";
import Post from "domains/Posts/Post";
import PostDetail from "domains/Posts/PostDetail";


export async function getPosts(params, skip) {
    const response = await api.get("/posts", { params: { ...params, skip } });
    const posts = apiNormalize.responseMapping(response.data, postsMapping)
    const normalizedPosts = posts.map(post => new Post(post).get());
    return normalizedPosts
}

export async function getSelectedPostDetails(params) {
    const response = await api.get("/posts/details", { params: { ...params } });
    const posts = apiNormalize.responseMapping(response.data, postsDetailsMapping)
    const normalizedPosts = posts.map(post => new PostDetail(post).get());
    console.log(normalizedPosts[0])
    return normalizedPosts[0]

    
}

export const deletePost = async (postId) => {
    try {
        const response = await api.post(`/posts/${postId}/deletePost`)
        return response.data
    }
    catch (error) {
        handleRequestError(error)
        throw error;
    }
}