import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarAttribute, getAvatarOptions, getAvatarXp } from "services/avatarsService";
import { fetchSelectedAvatarDetails, fetchSelectedAvatarProfileDetails } from "store/modules/users/avatars/selectedAvatarDetailSlice";

export function UseAvatarProperties(item) {
    const property = useSelector((state) => {
        return (state.selectedAvatarDetails.details) ? state.selectedAvatarDetails.details : { tableProperties: [] };
    });

    const dispatch = useDispatch();

    useEffect(() => {
        fetchSelectedAvatarDetails(dispatch, { avatarId: item.avatarId });
    }, [item]);

    return property;
}

export function UseAvatarProfileProperties(item) {
    const profileProperty = useSelector((state) => {
        return (state.selectedAvatarDetails.profile) ? state.selectedAvatarDetails.profile : { tableProperties: [] };
    });

    const dispatch = useDispatch();

    useEffect(() => {
        fetchSelectedAvatarProfileDetails(dispatch, { avatarId: item.avatarId });
    }, [item]);

    return profileProperty;
}

export function UseAvatarOptions(id) {
    const [fieldOptions, setFieldOptions] = useState(null);

    const handleIdChange = async () => {
        setFieldOptions(await getAvatarOptions({ id: id }));
    }

    useEffect(() => {
        handleIdChange();
    }, [id]);

    return fieldOptions;
}

export function UseAvatarXps(id) {
    const [avatarXps, setAvatarXps] = useState(null);

    const handleIdChange = async () => {
        setAvatarXps(await getAvatarXp(id));
    }

    useEffect(() => {
        handleIdChange();
    }, [id]);

    return avatarXps;
}

export function UseAvatarAttributes(id) {
    const [avatarAttributes, setAvatarAttributes] = useState(null);

    const handleIdChange = async () => {
        setAvatarAttributes(await getAvatarAttribute(id));
    }

    useEffect(() => {
        handleIdChange();
    }, [id]);

    return avatarAttributes;
}