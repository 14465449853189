class PrivateChatDetail {
    constructor({ chatId, chatName, color}) {
        this.chatId = chatId
        this.chatName = chatName
        this.color = color
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'chatId',
                    name: "ID do Chat",
                    value: this.chatId,
                },
                {
                    key: 'chatName',
                    name: "Nome do Chat",
                    value: this.chatName,
                },
                {
                    key: 'chatId',
                    name: "Cor",
                    value: this.color,
                },
            ],
            chatId: this.chatId,
            chatName: this.chatName,
            color: this.color
        }
    }

    
}

export default PrivateChatDetail