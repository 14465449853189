import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'styles/Search.module.css'

import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { fetchPrivateChats } from 'store/modules/moderation/privateChats/privateChatsSlice'
import PrivateChatFilter from './Partials/Filter'
import { selectPrivateChatForDetails } from 'store/modules/moderation/privateChats/selectedPrivateChatSlice'
import Details from './Partials/Details'

export default function PrivateChats({ chatId, chatName, memberId, adminId }) {
    const verifySpace = useUserPermission(SMIPermissionsCode.SPACE_SEARCH);
    const verifySpaceDetails = useUserPermission(SMIPermissionsCode.SPACE_DETAILS_VIEW);
    //TODO: fazer verifyChat

    const [privatechatsHeaders, setPrivateChatsHeaders] = useState([
        { title: 'ID do Chat', key: 'smallChatId', copy: 'id', detailable: verifySpaceDetails, appear: true },
        { title: 'Nome do Chat', key: 'chatName', appear: true },
    ]);

    const [privateChatsMomentHeaders, setPrivateChatsMomentHeaders] = useState([...privatechatsHeaders]);

    const handleAppearHeader = (item) => {
        setPrivateChatsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setPrivateChatsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const privateChats = useSelector((state) => {
        return Array.isArray(state.privateChats.data) ? state.privateChats.data : []
    })

    const selectedPrivateChat = useSelector((state) => {
        return state.selectedPrivateChat
    })

    const skip = useSelector((state) => {
        return (state.privateChats.currentSkip) ? state.privateChats.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.privateChats.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.privateChats.filters) ? state.privateChats.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [privateChats]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (chatId || chatName || adminId || memberId) {
            setLoading(true)
            fetchPrivateChats(dispatch, { chatId, chatName, adminId, memberId }, 0);
        }
    }

    const search = () => {
        try {
            setLoading(true)
            const query = new URLSearchParams();
            if (filter.form.chatId) query.append('chatId', filter.form.chatId);
            if (filter.form.chatName) query.append('chatName', filter.form.chatName);
            if (filter.form.memberId) query.append('memberId', filter.form.idAvatar);
            if (filter.form.adminId) query.append('adminId', filter.form.idModel);
            window.history.pushState({}, '', `/moderation/privateChats?${query.toString()}`);
            fetchPrivateChats(dispatch, filter.form, 0);
        } catch (error) {
            console.log(error)
        }
    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchPrivateChats(dispatch, filter.form, skip + 200);
    })

    const handlePrivateChatsDetails = (item) => {
        dispatch(selectPrivateChatForDetails({ id: item.id }))
    }

    return (
        <>
            {verifySpace && <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-7'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={privatechatsHeaders}
                    headers={privateChatsMomentHeaders}
                        slotHeader={<PrivateChatFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                        title='Buscar Chats Privados'
                        onDetailRow={handlePrivateChatsDetails}
                        isLoading={isLoading && privateChats.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        items={privateChats}
                        slotFooter={
                            <div className={styles.slotFooter}>
                                <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                {(privateChats.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : privateChats.length} resultados</span>}
                            </div>
                        }
                    />
                </div>
                <div className='col-span-5'>
                    {selectedPrivateChat.id && <Details item={selectedPrivateChat} />}
                </div>
            </div>}
        </>
    )
}
