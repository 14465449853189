import React, { useState, useEffect } from 'react'
import BasicTable from 'components/BasicTable'
import Button from 'components/form/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../styles/Search.module.css'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { selectWebSubscriptionForDetails } from 'store/modules/administration/webSubscription/selectedWebSubscriptionSlice'
import { fetchServers } from 'store/modules/administration/servers/serversSlice'
import { createServer, deleteServer } from 'services/serversService'
import { toast } from 'react-toastify'

export default function Servers({ userId, discord, status }) {
    const verifyWebSubscription = useUserPermission(SMIPermissionsCode.WEB_SUBSCRIPTION_VIEW);

    const [headers, setHeaders] = useState([
        { title: 'Nome do Servidor', key: 'name', copy: 'name', appear: true },
        { title: 'Endereço IP', key: 'ip', appear: true },
        { title: 'Porta', key: 'port', appear: true },
        { title: 'Versão', key: 'version', appear: true },
        { title: 'Avatares Online', key: 'avatarsOnline', appear: true },
        { title: 'Espaços Online', key: 'spacesOnline', appear: true },
        { title: 'Uso de CPU', key: 'cpuUsage', appear: true },
        { title: 'Uso de Memória', key: 'memoryUsage', appear: true },
        { title: 'Bytes Recebidos', key: 'receivedBytes', appear: true },
        { title: 'Bytes Enviados', key: 'sentBytes', appear: true },
    ]);

    const [momentHeaders, setMomentHeaders] = useState(headers);
    const [serverNames, setServerNames] = useState([]);

    const handleAppearHeader = (item) => {
        setHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const servers = useSelector((state) => {
        return Array.isArray(state.servers.data) ? state.servers.data : []
    })

    const skip = useSelector((state) => {
        return (state.servers.currentSkip) ? state.servers.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.servers.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.servers.filters) ? state.servers.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [servers]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()

        let interval = setInterval(() => {
            handleParams()
        }, 5 * 1000);

        return () => {
            clearInterval(interval)
        }
    }, [])

    const handleParams = async () => {
        setLoading(true)
        fetchServers(dispatch);
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.userId) query.append('userId', filter.form.userId);
        if (filter.form.discord) query.append('discord', filter.form.discord);
        if (filter.form.status) query.append('status', filter.form.status);
        //window.history.pushState({}, '', `/administration/webSubscriptions?${query.toString()}`);
        fetchServers(dispatch);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchServers(dispatch);
    })


    const handleWebSubscriptionDetails = (item) => {
        dispatch(selectWebSubscriptionForDetails({ userId: item.userId }))
    }

    const handleCreateServer = () => {
        createServer().then(() => {
            handleParams();
            toast.success("Servidor criado com sucesso. Aguarde alguns segundos para que ele apareça na lista.");
        })
    }

    const handleSelect = (item) => {
        let tempArr = [...serverNames];

        if (!tempArr.includes(item.name)) {
            tempArr.push(item.name);
        }
        else {
            let index = tempArr.indexOf(item.name);
            tempArr.splice(index, 1);
        }
        setServerNames(tempArr);
    }

    const handleDeleteServer = () => {
        deleteServer(serverNames).then((res) => {
            setServerNames([]);
            handleParams();
            toast.success("Servidor excluído com sucesso. Aguarde alguns segundos para que ele desapareça da lista.");
        }).catch((err) => {
            console.log(err);
        })
       
    }

    return (
        <div className='grid gap-4'>

            {verifyWebSubscription && <div className='col-span-8'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={headers}
                    headers={momentHeaders}
                    selectItems={servers.map((value) => {
                        return {
                            item: value,
                            selected: serverNames.includes(value.name)
                        }
                    })}
                    isSelectable={true}
                    selectKey={"name"}
                    slotHeader={<></>}
                    title='Servidores'
                    onDetailRow={handleWebSubscriptionDetails}
                    isLoading={isLoading && servers.length === 0}
                    slotActions={<></>}
                    onSelectItem={handleSelect}
                    slotFooter={
                        <div className={styles.slotFooter} style={{flex: 1}}>
                            {(servers.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : servers.length} resultados</span>}
                            <div className='flex gap-2' style={{marginLeft: "auto"}}>
                                {
                                    serverNames.length > 0 ? (
                                        <>
                                            <Button onClick={handleDeleteServer} text="Excluir servidor" color="cyan" />
                                        </>
                                    ) : (null)
                                }

                                <Button onClick={handleCreateServer} text="Criar servidor" color="cyan" />
                            </div>
                        </div>
                    }
                />
            </div>}
            <div className='col-span-4'>
                {
                    /*{selectedWebSubscription.userId && <Details item={selectedWebSubscription} />}*/
                }
            </div>
        </div>
    )
}
