import PrivateChats from "Pages/Moderation/PrivateChats";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchPrivateChatsFilters } from "store/modules/moderation/privateChats/privateChatsSlice";

export function PrivateChatsUrl(){
    const [searchParams, setSearchParams] = useSearchParams();
    const chatId = searchParams.get("chatId");
    const chatName = searchParams.get("chatName");
    const adminId = searchParams.get("adminId");
    const memberId = searchParams.get("memberId");

    const dispatch = useDispatch()

    if (chatId || chatName || adminId || memberId) {
        fetchPrivateChatsFilters(dispatch, {chatId, chatName, chatId, memberId })
        return <PrivateChats chatId={chatId ?? null} chatName={chatName ?? null} adminId={adminId ?? null} memberId={memberId ?? null}/>;
    }
    else{
        return <PrivateChats/>;
    }

}