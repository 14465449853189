const serverMappings = item => ({
    name: item.name ?? null,
    ip: item.ip ?? null,
    port: item.port ?? null,
    avatarsOnline: item.avatarsOnline ?? 0,
    spacesOnline: item.spacesOnline ?? 0,
    version: item.version ?? 0,
    performance: item.performance ?? {

    },
})

export {serverMappings}