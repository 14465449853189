import { getServers } from "services/serversService";

const { createSlice } = require("@reduxjs/toolkit");

const serversSlice = createSlice({
    name: 'servers',
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {

        }
    },
    reducers: {
        setServersData: (state, action) => {
            return { ...state, data: action.payload }
        },
        setCurrentSkip: (state, action) => {
            return { ...state, currentSkip: action.payload }
        },
        setHasMore: (state, action) => {
            return { ...state, hasMore: action.payload }
        },
        setServersFilter: (state, action) => {
            return { ...state, filters: action.payload }
        }
    }
})

export async function fetchServers(dispatch, skip =0) {
    try {
        const LIMIT = 200;
        const servers = await getServers();
        dispatch(setCurrentSkip(skip));
        dispatch(setServersData(servers));

        if (servers.length < LIMIT) {
            dispatch(setHasMore(false));
        }
        else {
            dispatch(setHasMore(true));
        }
    }
    catch(error) {
        console.error('Erro ao obter Servers', error)
        dispatch(setCurrentSkip(0));
        dispatch(setServersData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchModLogsFilters(dispatch, params){
    //dispatch(setModLogsFilter(params));
}

export const { setServersData, setCurrentSkip, setHasMore } = serversSlice.actions
export default serversSlice.reducer