import { getPrivateChatLogs } from "services/privateChatLogService";


const { createSlice } = require("@reduxjs/toolkit");

const privateChatLogsSlice = createSlice({
    name:"privateChatLogs",
    initialState: {
        data: [],
        currentSkip: 0,
        hasMore: false,
        filters: {
            avatarId: '',
            chatId: '',
            dateFrom: '',
            dateTo: '',
            messageText: ''
        }
    },
    reducers: {
        setPrivateChatLogsData: (state, action) => {
            return {...state, data: action.payload}
        },
        addPrivateChatLogsData: (state, action) => {
            return {...state, data: [...state.data, ...action.payload]}
        },
        setCurrentSkip: (state, action) => {
            return {...state, currentSkip: action.payload}
        },
        setHasMore: (state, action) => {
            return {...state, hasMore: action.payload}
        },
        setPrivateChatLogsFilter: (state, action) => {
            return {...state, filters: action.payload}
        }
    },
})

export async function fetchPrivateChatLogs(dispatch, params, skip){
    const LIMIT = 200;
    try{
        const items = await getPrivateChatLogs(params, skip);
        dispatch(setCurrentSkip(skip));

        if(skip>0){
            dispatch(addPrivateChatLogsData(items))
        }
        else{
            dispatch(setPrivateChatLogsData(items));
        }
        
        if(items.length<LIMIT){
            dispatch(setHasMore(false));
        }
        else{
            dispatch(setHasMore(true));
        }
    }
    catch(error){
        console.error('Erro ao obter ChatLogs Privados', error)
        dispatch(setCurrentSkip(0));
        dispatch(setPrivateChatLogsData([]));
        dispatch(setHasMore(false));
    }
}

export async function fetchPrivateChatLogsFilters(dispatch, params){
    dispatch(setPrivateChatLogsFilter(params));

    console.log(params)
}

export const { setPrivateChatLogsData, setCurrentSkip, setHasMore, addPrivateChatLogsData, setPrivateChatLogsFilter } = privateChatLogsSlice.actions
export default privateChatLogsSlice.reducer