class PrivateChat {
    constructor({ chatId, chatName}) {
        this.chatId = chatId
        this.chatName = chatName
    }

    get() {
        return {
            id: this.chatId,
            smallChatId: this.smallID(this.chatId),
            chatName: this.chatName,
        }
    }

    smallID(id) {
        return id?.slice(0, 7)
    }
}

export default PrivateChat