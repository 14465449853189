

import moment from "moment"

class Post {
    constructor({ postId, avatarId, avatarFirstName, avatarLastName, avatarNameInstance, avatarIcon, targetType, title, date, }) {
        this.postId = postId
        this.avatarId = avatarId
        this.targetType = targetType
        this.title = title
        this.date = date
        this.avatarFirstName = avatarFirstName
        this.avatarLastName = avatarLastName
        this.avatarNameInstance = avatarNameInstance
        this.avatarIcon = avatarIcon
    }

    get() {
        return {
            postId: this.postId,
            avatarId: this.avatarId,
            targetType: this.getTargetType(this.targetType),
            title: this.title == '' ? '-' : this.title ?? '-',
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            avatarFullName: this.avatarFullName(this.avatarFirstName, this.avatarLastName, this.avatarNameInstance),
            avatarIcon: this.icon(this.avatarIcon, this.avatarFirstName, this.avatarLastName),
        }
    }

    getTargetType(type) {
        const types = {
            A: 'Postada no Perfil',
            P: 'Resposta à uma postagem',
        }
        return types[type]
    }

    avatarFullName(avatarFirstName, avatarLastName, avatarNameInstance) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else return avatarFirstName + " " + avatarLastName;
    }

    icon(iconStr, avatarFirstName, avatarLastName) {
        return (!avatarFirstName || !avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${iconStr}`
    }
}

export default Post