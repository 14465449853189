import moment from "moment"

class News {
    constructor({ newsId, title, shortText, fullText, newsImageUrl, date, authorId, authorFirstName, authorLastName, authorNameInstance, authorIcon, categories, scheduleTime }) {
        this.newsId = newsId
        this.title = title
        this.shortText = shortText
        this.fullText = fullText
        this.newsImageUrl = newsImageUrl
        this.date = date
        this.authorId = authorId
        this.authorFirstName = authorFirstName
        this.authorLastName = authorLastName
        this.authorNameInstance = authorNameInstance
        this.authorIcon = authorIcon
        this.categories = categories
        this.scheduleTime = scheduleTime
    }

    get() {
        return {
            newsId: this.newsId,
            smallNewsId: this.smallId(this.newsId),
            title: this.title['pt-BR'],
            shortText: this.shortText['pt-BR'],
            fullText: this.fullText['pt-BR'],
            categories: this.categories,
            newsImageUrl: this.newsImageUrl,
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            authorId: this.authorId,
            smallAuthorId: this.smallId(this.authorId),
            authorFirstName: this.authorFirstName,
            authorLastName: this.authorLastName,
            authorName: this.avatarFullName(this.authorNameInstance, this.authorFirstName, this.authorLastName),
            authorNameInstance: this.authorNameInstance,
            authorIcon: this.authorIcon,
            scheduleTime: this.scheduleTime
        }
    }


    avatarFullName(avatarNameInstance, avatarFirstName, avatarLastName) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
        else return avatarFirstName + " " + avatarLastName;
    }

    smallId(id) {
        return id?.slice(0, 7)
    }
}

export default News